import React, { useEffect, useState } from 'react';
import BalanceIcon from '../../otherassets/balance.png';
import PinModal from '../Pin/PinModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { cashoutInvestmentReturns } from '../../Service/Investment/InvestmentService';
import { getUser } from '../../Service/GetUser/UserService';
import SetPin from '../Pin/SetPin';

const InvestmentBalanceBox = ({ balance }) => {
  const navigate = useNavigate();
  const [isPinModalVisible, setIsPinModalVisible] = useState(false);
  const [isSetPinModalVisible, setIsSetPinModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [balanceVisible, setBalanceVisible] = useState(true);
  const [userData, setUserData] = useState(null);

  // Toggle balance visibility
  const toggleBalanceVisibility = () => {
    setBalanceVisible(prevVisible => !prevVisible);
  };

  const handleSubmitPin = async (pin) => {
    setIsSubmitting(true);
    const payload = {
      pin: pin.join(''),
    };

    try {
      const response = await cashoutInvestmentReturns(payload);

      if (response.status === 'success') {
        toast.success(response.message, {
          autoClose: 3000,
        });

        setIsPinModalVisible(false);
        navigate('/investments');
      } else {
        setIsPinModalVisible(false);
        throw new Error(response.message || 'Unexpected response');
      }
    } catch (error) {
      console.error('Failed to process the cashout:', error);

      const errorMessages = error.response?.data?.message;
      if (Array.isArray(errorMessages)) {
        errorMessages.forEach((msg) => {
          toast.error(msg, {
            autoClose: 5000,
          });
        });
      } else {
        toast.error(error.response?.data?.message || 'Failed to process cashout. Please try again.', {
          autoClose: 5000,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const data = await getUser();
      setUserData(data.customer);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const closePinModal = () => {
    setIsPinModalVisible(false);
  };

  const closeSetPinModal = () => {
    setIsSetPinModalVisible(false);
  };

  // Open the Pin Modal
  const openPinModal = () => {
    setIsPinModalVisible(true);
  };

  // Open the Set Pin Modal
  const openSetPinModal = () => {
    setIsSetPinModalVisible(true);
  };

  // Call fetchUserData initially
  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div>
      <div className="card shadow-sm mb-4">
        <div className="card-body walletspace">
          <div className="d-flex justify-content-between">
            <div className='d-flex align-items-center'>
              <img src={BalanceIcon} width={50} alt="oneplus" />
              <div className='mx-3'>
                <p className='fs-5 text-black2 m-0'>Payout balance</p>
                <h4 className='fs-2 fw-bold text-black2 m-0'>{balanceVisible ? '₦' + balance : <span>*****</span>}</h4>
              </div>
              <h2 className="card-text cursor-pointer">
                <i className={`bx fs-2 ${!balanceVisible ? 'bx-hide' : 'bx-show'} toggle-password`} onClick={toggleBalanceVisibility}></i>
              </h2>
            </div>

            {/* Conditionally show Set PIN or Cashout button based on PIN status */}
            {!userData?.pin || userData.pin === 'null' || userData.pin === '' ? (
              <div className='btn btn-blue pt-0 pb-0 fs-c3 btn-sm mt-3 mb-2 ms-5' onClick={openSetPinModal}>
                Cashout
              </div>
            ) : (
              <div className='btn btn-blue pt-0 pb-0 fs-c3 btn-sm mt-3 mb-2 ms-5' onClick={openPinModal}>
                Cashout
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Show the appropriate modal */}
      {isPinModalVisible && (
        <PinModal
          closePinModal={closePinModal}
          handleSubmitPin={handleSubmitPin}
          isSubmitting={isSubmitting}
        />
      )}

      {isSetPinModalVisible && (
        <SetPin
          closeSetPinModal={closeSetPinModal}
          handleSubmitSetPin={fetchUserData}  // Make sure to handle successful PIN submission
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default InvestmentBalanceBox;
