import React, { useEffect, useState } from 'react';
import { formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';
import FormInput from '../../components/Form/FormInput';
import flip from '../../assets/image/icons/flip-backward.png';
import { toast } from 'react-toastify';
import PaymentProvider from '../../components/Transaction/PaymentProvider';
import { fetchAccDetails, fetchAllBanks, withdrawFunds } from '../../Service/Transaction/TransactionService';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../Service/GetUser/UserService';
import PinModal from '../../components/Pin/PinModal';
import SetPin from '../../components/Pin/SetPin'; // Import SetPin

const Withdraw = () => {
    const navigate = useNavigate();
    const [bal, setBal] = useState('---');
    const [banks, setBanks] = useState([]);
    const [amount, setAmount] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const [accountDetails, setAccountDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPinModalVisible, setIsPinModalVisible] = useState(false);
    const [isSetPinModalVisible, setIsSetPinModalVisible] = useState(false); // Track SetPin modal visibility
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getUser();
                if (response.status === 'success') {
                    setBal(response.customer.ngnbalance);
                    setUserData(response.customer); // Store user data to check PIN
                } else {
                    throw new Error(response.message || 'Unexpected response');
                }
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                toast.error(error.response?.data?.message || 'Failed to get user data. Please try again.', {
                    autoClose: 5000,
                });
            }
        };

        const fetchBanks = async () => {
            try {
                const banks = await fetchAllBanks();
                const bankOptions = banks.map(bank => ({
                    value: bank.bankcode,
                    label: bank.name,
                }));
                setBanks(bankOptions);
            } catch (error) {
                console.error('Failed to fetch banks:', error);
                toast.error(error.response?.data?.message || 'Failed to get banks. Please try again.', {
                    autoClose: 5000,
                });
            }
        };

        fetchUserData();
        fetchBanks();
    }, []);

    const handleFetchAccountDetails = async () => {
        try {
            setIsLoading(true);
            const accountNumberToFetch = accountNumber.trim();
            if (accountNumberToFetch.length === 10) {
                const bankCode = selectedBank;
                const response = await fetchAccDetails({ accno: accountNumberToFetch, code: bankCode });
                setAccountDetails(response);
            } else {
                setAccountDetails(null);
            }
        } catch (error) {
            console.error('Failed to fetch account details:', error);
            toast.error('Failed to fetch account details. Please try again.', { autoClose: 5000 });
            setAccountDetails(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (accountNumber.length === 10 && selectedBank) {
            handleFetchAccountDetails();
        } else {
            setAccountDetails(null);
        }
    }, [accountNumber, selectedBank]);

    const handleContinue = async (e) => {
        e.preventDefault();
        const parsedNumber = parseFloat(amount);
        if (!isNaN(parsedNumber) && parsedNumber > 0.9) {
            if (!userData?.pin || userData.pin === 'null' || userData.pin === '') {
                // If no PIN is set, show SetPin modal
                setIsSetPinModalVisible(true);
            } else {
                // If PIN is set, show the Pin Modal
                setIsPinModalVisible(true);
            }
        } else {
            toast.error('Please enter a valid amount.', {
                autoClose: 3000,
            });
        }
    };

    const handleSubmitPin = async (pin) => {
        setIsSubmitting(true);
        const parsedNumber = parseFloat(amount);
        const payload = {
            "amount": parsedNumber.toString(),
            "bank": selectedBank,
            "accountnumber": accountNumber,
            "pin": pin.join(''),
        };

        try {
            const response = await withdrawFunds(payload);

            if (response.status === 'success') {
                toast.success(response.message, {
                    autoClose: 3000,
                });
                setIsPinModalVisible(false);
                navigate('/');
            } else {
                setIsPinModalVisible(false);
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to process the cashout:', error);

            const errorMessages = error.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => {
                    toast.error(msg, {
                        autoClose: 5000,
                    });
                });
            } else {
                toast.error(error.response?.data?.message || 'Failed to process cashout. Please try again.', {
                    autoClose: 5000,
                });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const closePinModal = () => {
        setIsPinModalVisible(false);
    };

    const closeSetPinModal = () => {
        setIsSetPinModalVisible(false);
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    {/* Back Button */}
                    <div className='d-flex align-items-center px-0'>
                        <h5 className="card-title me-2" onClick={() => window.history.back()}>
                            <img src={flip} alt='flip-backward' />
                        </h5>
                        <div className="text-black fw-normal fs-5">
                            Withdraw
                        </div>
                    </div>

                    <form className='mt-4' onSubmit={handleContinue}>
                        <FormInput
                            label="Amount"
                            type="text"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Enter amount"
                        />
                        <small className='text-grey fs-c7'>
                            Current amount
                            <span className='badge bg-success-dim1 text-success rounded p-2 ms-1'>
                                N{formatNumberWithCommas(bal)}
                            </span>
                        </small>

                        <FormInput
                            label="Bank"
                            id="bankSelect"
                            type="select"
                            value={selectedBank}
                            onChange={(e) => setSelectedBank(e.target.value)}
                            placeholder="Choose a bank"
                            options={banks}
                        />

                        <FormInput
                            label="Account Number"
                            type="number"
                            value={accountNumber}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value.length <= 10) {
                                    setAccountNumber(value);
                                }
                            }}
                            placeholder="Account Number"
                        />

                        {!selectedBank && accountNumber.length === 10 && (
                            <div style={{ color: 'red', marginTop: '1px' }}>
                                Please select a bank.
                            </div>
                        )}

                        {isLoading && selectedBank ? (
                            <div>FETCHING ACCOUNT DETAILS</div>
                        ) : (
                            accountNumber.length === 10 && selectedBank && (
                                <FormInput
                                    label="Account Name"
                                    type="text"
                                    value={accountDetails ? accountDetails.account.responseBody.accountName : (accountDetails && accountDetails.message ? accountDetails.message : 'NULL')}
                                    readOnly
                                />
                            )
                        )}

                        <button
                            type="submit"
                            className='btn btn-primary border-0 w-100 py-2 mt-2'
                            disabled={isSubmitting || !selectedBank || !accountDetails}
                        >
                            {isSubmitting ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Continue"
                            )}
                        </button>

                        <PaymentProvider />
                    </form>
                </div>
            </div>

            {/* Pin Modal */}
            {isPinModalVisible && (
                <PinModal
                    closePinModal={closePinModal}
                    handleSubmitPin={handleSubmitPin}
                    isSubmitting={isSubmitting}
                />
            )}

            {/* Set Pin Modal */}
            {isSetPinModalVisible && (
                <SetPin
                    closeSetPinModal={closeSetPinModal}
                    handleSubmitSetPin={setUserData}
                    isSubmitting={isSubmitting}
                />
            )}

        </div>
    );
};

export default Withdraw;
