import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import flip from '../../assets/image/icons/flip-backward.png';
import FormInput from '../../components/Form/FormInput';
import { fetchElectricCodes, verfiyMeterNumber } from '../../Service/Utility/ElectricityService';

const Electricity = () => {
  const [MeterNo, setMeterNo] = useState('');
  const [ElectricCode, setElectricCode] = useState('');
  const [MeterType, setMeterType] = useState('01'); // Default to 'PREPAID'
  const [amount, setAmount] = useState('');
  const [electricCodes, setElectricCodes] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState('Unverified');
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();


  //fetch and display electric codes
  useEffect(() => {
    fetchElectricCodes()
      .then(response => {
        // console.log('API response:', response);

        if (response.status === 'success' && Array.isArray(response.data)) {
          setElectricCodes(response.data);
        } else {
          toast.error('Failed to fetch electric codes or data is not in the expected format');
          setElectricCodes([]); // Ensure fallback to an empty array
        }
      })
      .catch(err => {
        console.error('Error fetching electric codes:', err);
        toast.error('An error occurred while fetching electric codes');
        setElectricCodes([]); // Ensure fallback to an empty array
      });
  }, []);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  // Function to handle button selection
  const handleSelection = (value) => {
    setMeterType(value);
  };

  // Function to verify meter number
  const handleVerify = async () => {
    const verfiydata = {
      meterno: MeterNo,
      code: ElectricCode,
    };


    try {
      const response = await verfiyMeterNumber(verfiydata); // Pass the data directly
      if (response.status === 'success') {
        setVerificationStatus('Verified'); // Update verification status
        setIsVerified(true); // Set verified state to true
        toast.success(response.message);
      } else {
        toast.error('Verification failed. Please check your details.');
        setVerificationStatus('Unverified');
        setIsVerified(false);
      }
    } catch (err) {
      console.error('Error verifying meter number:', err);
      const errorMessages = err.response?.data?.message || ['An error occurred during verification'];

      if (Array.isArray(errorMessages)) {
        errorMessages.forEach((msg) => {
          toast.error(msg, {
            autoClose: 5000,
          });
        });
      } else {
        // Display a single error message or a default message
        toast.error(err.response?.data?.message || 'Failed to Verfiy meter number. Please try again.', {
          autoClose: 5000,
        });
      }

      setVerificationStatus('Unverified'); // Reset status on error
      setIsVerified(false); // Reset verified state
    }
  };

  const validateInputs = () => {
    if (!MeterNo || !ElectricCode || !MeterType || !amount) {
      toast.error('Fill in the form fields.');
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      navigate('/review/electricty-bill', { //navigate to review page, with state containing data
        state: {
          number: MeterNo,
          amount,
          code: ElectricCode,
          type: MeterType,
        },
      });
    }
  };


  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-5">
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <div className='container'>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title" onClick={() => window.history.back()}>
                    <img src={flip} alt="flip-backward" />
                  </h5>
                </div>
                <h4 className="card-text mb-4">Electricity subscription</h4>
              </div>
              {/* Form Section */}
              <form onSubmit={handleSubmit} >
                <div className='container'>
                  <FormInput
                    label="Select Provider"
                    id="ElectricCode"
                    type="select"
                    options={(electricCodes || []).map(code => ({
                      value: code.code,
                      label: code.description,
                    }))}
                    value={ElectricCode}
                    onChange={(e) => setElectricCode(e.target.value)}
                    placeholder="Eg: Eko Electric - EKEDC (PHCN)"
                  />

                  {/* Button inputs */}
                  <div className='d-flex justify-content-center mb-2' style={{ display: 'flex', gap: '10px', padding: '10px', border: '1px solid #f0e6d2', borderRadius: '8px' }}>
                    <button
                      type="button" // Ensure it's not a submit button
                      onClick={() => handleSelection('01')}
                      style={{
                        backgroundColor: MeterType === '01' ? '#ff7600' : '#c0c0c0',
                        color: '#fff',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        outline: 'none'
                      }}
                    >
                      PREPAID
                    </button>
                    <button
                      type="button" // Ensure it's not a submit button
                      onClick={() => handleSelection('02')}
                      style={{
                        backgroundColor: MeterType === '02' ? '#ff7600' : '#c0c0c0',
                        color: '#fff',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        outline: 'none'
                      }}
                    >
                      POSTPAID
                    </button>
                  </div>

                  {/* //hide the amount input, untill the meter number is Verified */}
                  {isVerified && (
                    <FormInput
                      label="Amount"
                      id="amount"
                      type="number"
                      value={amount}
                      onChange={handleInputChange(setAmount)}
                      placeholder="Enter amount"
                    />
                  )}

                  <FormInput
                    label="Meter Number"
                    id="MeterNo"
                    type="number"
                    value={MeterNo}
                    onChange={handleInputChange(setMeterNo)}
                    placeholder="Enter Meter Number"
                  />

                  {/* Verification button */}
                  <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn nlbtn mb-4"
                      id="verify-button"
                      style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '8px' }}
                      onClick={handleVerify} // Call verification function
                    >
                      Verify
                    </button>
                    <span className={`fw-bold ${isVerified ? 'text-success' : 'text-danger'}`} id='verification-status'>
                      {verificationStatus}
                    </span>
                    {/* Show verification status */}
                  </div>

                  <button
                    onClick={handleSubmit}
                    className="btn w-100 nlbtn p-3"
                    disabled={!isVerified} // Disable button until verified
                    style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '16px' }}
                    type="submit"
                  >
                    Continue
                  </button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Electricity;
