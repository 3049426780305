import { TbArrowBackUp } from "react-icons/tb";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormRow from "./components/FormRow";
import SubmitBtn from "./components/SubmitBtn";
import { AuthContext } from "./context_api/AuthContext";

const Details = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const locate = useLocation();
  // console.log(locate.state);
  const navigate = useNavigate();
  const state = locate.state;
  const { setAuthStage } = useContext(AuthContext);

  // Load saved values from local storage on initial render
  useEffect(() => {
    setFirstName(localStorage.getItem("firstName") || "");
    setLastName(localStorage.getItem("lastName") || "");
    setPhoneNumber(localStorage.getItem("phoneNumber") || "");
    setLocation(localStorage.getItem("location") || "");
    setAddress(localStorage.getItem("address") || "");
    setCity(localStorage.getItem("city") || "");
  }, []);

  // Save values to local storage whenever an input changes
  const handleInputChange = (setter, key) => (e) => {
    setter(e.target.value);
    localStorage.setItem(key, e.target.value); // Persist each field
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !address ||
      !city ||
      !location
    ) {
      toast.error("All fields are required");
      return;
    }

    navigate("/register/otp/details/time", {
      state: {
        ...state,
        firstName,
        lastName,
        phoneNumber,
        location,
        address,
        city,
      },
    });

    // Clear local storage after navigation
    [
      "firstName",
      "lastName",
      "phoneNumber",
      "location",
      "address",
      "city",
    ].forEach((key) => localStorage.removeItem(key));
    setAuthStage(4);
    setIsLoading(false);
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <button
                className="navigate-btn mb-10"
                onClick={() => navigate(1)}
              >
                <TbArrowBackUp />
              </button>

              <div className="d-flex flex-column details-container mt">
                <h5>Set up your account</h5>
                <p>Please fill in your personal details.</p>

                <form id="userLogin" onSubmit={onSubmit} className="mb-4">
                  <div className="d-flex justify-content-center gap-2">
                    <FormRow
                      labelText="first name"
                      type="text"
                      className="form-control"
                      id="firstname"
                      name="firstname"
                      value={firstName}
                      handleChange={handleInputChange(
                        setFirstName,
                        "firstName"
                      )}
                      placeholder="First"
                    />
                    <FormRow
                      labelText="last name"
                      type="text"
                      className="form-control"
                      id="lastname"
                      name="lastname"
                      value={lastName}
                      handleChange={handleInputChange(setLastName, "lastName")}
                      placeholder="Last"
                    />
                  </div>
                  <FormRow
                    labelText="Phone Number"
                    type="tel"
                    className="form-control"
                    id="phone-number"
                    name="phonenumber"
                    value={phoneNumber}
                    handleChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleInputChange(setPhoneNumber, "phoneNumber")(e);
                      }
                    }}
                    placeholder="08185643821"
                  />

                  <FormRow
                    labelText="Address"
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    value={address}
                    handleChange={handleInputChange(setAddress, "address")}
                    placeholder="Your Address"
                  />
                  <FormRow
                    labelText="Location"
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    value={location}
                    handleChange={handleInputChange(setLocation, "location")}
                    placeholder="Your State"
                  />
                  <FormRow
                    labelText="City"
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={city}
                    handleChange={handleInputChange(setCity, "city")}
                    placeholder="Your City"
                  />

                  <SubmitBtn
                    type="submit"
                    isLoading={isLoading}
                    text="Submitting"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
