import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard'
import Layout from './components/Layout/Layout';
import Calculator from './pages/Calculator/Calculator';
import CalculatorSummary from './pages/Calculator/CalculatorSumarry';
import { handleDataTable } from './customjs/script';
import Investment from './pages/Investment/Investment';
import UtilityBills from './pages/UtilityBills/UtilityBills';
import SeeAllInvestment from './pages/Investment/SeeAllInvestment';
import InvestmenDetails from './pages/Investment/InvestmentDetails';
import EnterInvestmentUnit from './pages/Investment/EnterInvestmentUnit';
import Airtime from './pages/UtilityBills/Airtime';
import DataSubscription from './pages/UtilityBills/DataSubscription';
import Electricity from './pages/UtilityBills/Electricity';
import Cable from './pages/UtilityBills/Cable';
import AirtimeReview from './pages/UtilityBills/Review/AirtimeReview';
import DataSubscriptionReview from './pages/UtilityBills/Review/DataSubscriptionReview';
import ElectrictyReview from './pages/UtilityBills/Review/ElectrictyReview';
import CableReview from './pages/UtilityBills/Review/CableReview';
import Deposit from './pages/Deposit/Deposit';
import VerifyPaymentFunding from './pages/VerifyPayment/verifypaymentfunding';
import Withdraw from './pages/Withdraw/Withdraw';
import TransactionList from './pages/Transaction/TransactionList';
import TransactionDetail from './pages/Transaction/TransactionDetail';
import Profile from './pages/Profile/Profile';
import EditProfile from './pages/Profile/EditProfile';
import AboutPage from './pages/Profile/AboutPage';
import SecurityPage from './pages/Profile/SecurityPage';
import CustomerSupportPage from './pages/Profile/CustomerSupportPage';
import AllTransactions from './pages/Transaction/AllTransactions';
import NotifcationPage from './pages/Notifcation/NotifcationPage';
import ComingSoon from './pages/ComingSoon';
import AddToHomeInstruction from './pages/Instruction/AddToHomeInstruction';

const AppRoutes = () => {

    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        // Check if the current route matches the customer page route
        if (location.pathname === '/subscribers' || location.pathname === '/transactions'
            || location.pathname === '/roles-permission'
            || location.pathname === '/active-investors'
            || location.pathname === '/investor-noks' || location.pathname === '/investor-witnesses'
            || location.pathname === '/action-logs') {
            // Call your function from script.js
            handleDataTable();
        }
    }, [location, navigate]);


    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="addtohomeinstruction" element={<AddToHomeInstruction />} />

                {/* Transactions */}
                <Route path="transactions" element={<TransactionList />} />
                <Route path="alltransactions" element={<AllTransactions />} />

                <Route path="transaction-details" element={<TransactionDetail />} />
                {/* Investments */}
                <Route path="investments" element={<Investment />} />
                <Route path="investment/plans" element={<SeeAllInvestment />} />
                <Route path="investment/details" element={<InvestmenDetails />} />
                <Route path="investment/unit" element={<EnterInvestmentUnit />} />

                <Route path="utilitybills" element={<UtilityBills />} />
                <Route path="utilitybills/airtime" element={<Airtime />} />
                <Route path="utilitybills/data-subscription" element={<DataSubscription />} />
                <Route path="utilitybills/electricty" element={<Electricity />} />
                <Route path="utilitybills/cable" element={<Cable />} />
                {/* utility reviews */}
                <Route path="review/airtime" element={<AirtimeReview />} />
                <Route path="review/data-subscription" element={<DataSubscriptionReview />} />
                <Route path="review/electricty-bill" element={<ElectrictyReview />} />
                <Route path="review/cable" element={<CableReview />} />

                {/* Profile */}
                <Route path="account/profile" element={<Profile />} />
                <Route path="account/profile/edit" element={<EditProfile />} />
                <Route path="account/about" element={<AboutPage />} />
                <Route path="account/security" element={<SecurityPage />} />
                <Route path="account/customer-support" element={<CustomerSupportPage />} />



                <Route path="deposit" element={<Deposit />} />
                <Route path="verifypaymentfunding" element={<VerifyPaymentFunding />} />
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="comingsoon" element={<ComingSoon />} />


                <Route path="calulator" element={<Calculator />} />
                <Route path="calcs" element={<CalculatorSummary />} />

                {/* Notification */}
                <Route path="notification" element={<NotifcationPage />} />






                {/* Redirect for unknown routes */}
                {/* <Route path="*" element={ <Dashboard/> } /> */}
                <Route path="login" element={<Navigate to="/dashboard" />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes