import React, { useState, useEffect } from "react";
import Logo from "../../otherassets/maskable-icon.png";
import { useNavigate } from "react-router-dom";

function InstallBanner() {
  const [showInstallBanner, setShowInstallBanner] = useState(false);
  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
    setIsIOS(isIosDevice);

    const isMobile = window.innerWidth <= 768;
    const currentTime = Date.now();
    const lastDismissed = localStorage.getItem("iosBannerLastDismissed");

    // console.log({
    //   isIosDevice,
    //   isMobile,
    //   lastDismissed,
    //   currentTime,
    //   timeSinceDismissed: lastDismissed
    //     ? currentTime - parseInt(lastDismissed, 10)
    //     : "No dismissal logged",
    // });

    if (isIosDevice && isMobile) {
      if (!lastDismissed || currentTime - parseInt(lastDismissed, 10) >= 14 * 24 * 60 * 60 * 1000) {
        setShowInstallBanner(true);
      }
    } else if (!isIosDevice && isMobile) {
      const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        setInstallPromptEvent(event);
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      };
    }
  }, []);

  useEffect(() => {
    if (installPromptEvent) {
      setShowInstallBanner(true);
    }
  }, [installPromptEvent]);

  const handleIosDismissClick = () => {
    const currentTime = Date.now();
    localStorage.setItem("iosBannerLastDismissed", currentTime.toString());
    setShowInstallBanner(false);
  };

  const handleDismissClick = () => {
    setShowInstallBanner(false);
  };

  const handleInstallClick = () => {
    setShowInstallBanner(false);
    if (installPromptEvent) {
      installPromptEvent.prompt();
      installPromptEvent.userChoice.then((choiceResult) => {
        console.log(
          choiceResult.outcome === "accepted"
            ? "User accepted the install prompt"
            : "User dismissed the install prompt"
        );
      });
    }
  };

  const handleSeeHowClick = () => {
    navigate("/addtohomeinstruction");
  };

  if (!showInstallBanner) return null;

  return (
    <div className="install-banner bg-primary text-white p-2 fixed-bottom d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center pe-1">
        <img src={Logo} alt="App Logo" className="me-2" style={{ width: "40px", height: "40px" }} />
        {isIOS ? (
          <span>Add this website to your home screen</span>
        ) : (
          <span>Install our app!</span>
        )}
      </div>

      {isIOS ? (
        <>
          <button
            onClick={handleSeeHowClick}
            className="btn btn-light btn-sm me-2"
          >
            See How
          </button>
          <button
            onClick={handleIosDismissClick} 
            className="btn btn-outline-light btn-sm"
          >
            Dismiss
          </button>
        </>
      ) : (
        <div>
          <button
            onClick={handleInstallClick}
            className="btn btn-light btn-sm me-2"
          >
            Install
          </button>
          <button
            onClick={handleDismissClick}
            className="btn btn-outline-light btn-sm"
          >
            Dismiss
          </button>
        </div>
      )}
    </div>
  );
}

export default InstallBanner;
