import axios from 'axios';
import Cookies from 'js-cookie';


const uRL = process.env.REACT_APP_URL;

//update profile image
export const updateProfile = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/updateprofile`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to process update profile');
        }
    } catch (error) {
        console.error('Error updating profile:', error);
        throw error; // re-throw the error for further handling
    }
};


//change profile picture
export const changeDp = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/changeprofilepicture`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to update profile image');
        }
    } catch (error) {
        console.error('Error updating profile image:', error);
        throw error; // re-throw the error for further handling
    }
};

//change pin
export const changePin = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/pin/changepin`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to change Pin');
        }
    } catch (error) {
        console.error('Error changing pin:', error);
        throw error; // re-throw the error for further handling
    }
};

//set pin
export const setPin = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/pin/setpin`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to change Pin');
        }
    } catch (error) {
        console.error('Error changing pin:', error);
        throw error; // re-throw the error for further handling
    }
};


// change password
export const ChangePassword = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/changepassword`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to change password');
        }
    } catch (error) {
        console.error('Error changing password:', error);
        throw error; // re-throw the error for further handling
    }
};