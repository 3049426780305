import React, { useEffect, useState } from 'react'
import './PlanCarousel.css'
import { Link, useNavigate } from 'react-router-dom';
import bikeplanimg from '../../otherassets/plan-icon/bike.png';
import vanplanimg from '../../otherassets/plan-icon/minibus.png';
import jetplanimg from '../../otherassets/plan-icon/jetmover.png';
import truckplanimg from '../../otherassets/plan-icon/truck.png';
import { AbbreviateNumber } from "../../utilities/reusablefunctions_variables";

const PlanCard = ({slide}) => {
    const navigate = useNavigate();

    return (
        <div className="card card-container plan-container">
        <div className="d-flex align-items-center row plan-card">
            <div className="plan-img">
                <img src={(slide.which==="1")?bikeplanimg : (slide.which==="2")?vanplanimg : (slide.which==="3")?jetplanimg : truckplanimg} alt="..."/>
            </div>
            <div className="plan-info">
                <div className="card-body">
                    <h6 className="card-title mb-1 text-primary text-nowrap">{slide.name}</h6>
                    <p>Invest ₦{AbbreviateNumber(slide.amount)} for a {slide.percentage}% return in {slide.no_of} {(slide.duration=="1")?'Weeks':'Months'}</p>
                    <div onClick={() => navigate('/investment/details', { state: { plan:slide } })} className="btn btn-sm btn-primary">Invest</div>
                </div>
            </div>
          
        </div>
      </div>
    );
};

export default PlanCard;
