import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import flip from '../../../assets/image/icons/flip-backward.png';
import PinModal from '../../../components/Pin/PinModal';
import SetPin from '../../../components/Pin/SetPin'; // Import SetPin component
import { toast } from 'react-toastify';
import { electricSubscription } from '../../../Service/Utility/ElectricityService';
import { getUser } from '../../../Service/GetUser/UserService'; // Import getUser function

const ElectrictyReview = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { number, amount, code, type } = location.state || {};

    // State variables
    const [showPinModal, setShowPinModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSetPinModalVisible, setIsSetPinModalVisible] = useState(false); // Track SetPin modal visibility
    const [userData, setUserData] = useState(null);

    // Redirect if data is missing
    useEffect(() => {
        if (!number || !amount || !code) {
            toast.error('No data provided. Redirecting to the electricty subscription page.', {
                autoClose: 3000,
            });
            navigate('/electricty');
        }
    }, [number, amount, code, type, navigate]);

    // Fetch user data to check if PIN is set
    const fetchUserData = async () => {
        try {
            const data = await getUser();
            setUserData(data.customer);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const typeMap = {
        "01": "PREPAID",
        "02": "POSTPAID",
    };

    const codeMap = {
        "01": "Eko Electric - EKEDC (PHCN)",
        "02": "Ikeja Electric - IKEDC (PHCN)",
        "03": "Abuja Electric - AEDC",
        "04": "Kano Electric - KEDC",
        "05": "Portharcourt Electric - PHEDC",
        "06": "Jos Electric - JEDC",
        "07": "Ibadan Electric - IBEDC",
        "08": "Kaduna Electric - KAEDC",
        "09": "ENUGU Electric - EEDC",
        "10": "BENIN Electric - BEDC",
        "11": "YOLA Electric - YEDC",
    };

    const typeName = typeMap[type] || "Unknown";
    const codeName = codeMap[code] || "Unknown";

    // Handle Proceed action based on PIN status
    const handleProceed = () => {
        if (!userData?.pin || userData.pin === 'null' || userData.pin === '') {
            // If no PIN is set, show SetPin modal
            setIsSetPinModalVisible(true);
        } else {
            // If PIN is set, show Pin modal
            setShowPinModal(true);
        }
    };

    // Format amount to Naira currency format
    function formatToNaira(amount) {
        return new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency: 'NGN',
            minimumFractionDigits: 0, // You can change this to 2 if you want to show decimals
        }).format(amount);
    }

    // Handle PIN submission
    const handleSubmitPin = async (pin) => {
        setIsSubmitting(true); // Indicate that submission is in progress

        // Create the payload
        const payload = {
            pin: pin.join(''), // Combine the PIN array into a single string
            ElectricCode: code,
            MeterNo: number,
            amount,
            MeterType: type,
        };

        try {
            const response = await electricSubscription(payload);

            // Check if the response indicates success
            if (response.status === 'success') {
                toast.success(response.message, {
                    autoClose: 3000,
                });

                // Close the PIN modal
                setShowPinModal(false);
                navigate('/utilitybills');
            } else {
                // Handle unexpected response status
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to purchase electricty subscription:', error);

            const errorMessages = error.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => {
                    toast.error(msg, {
                        autoClose: 5000,
                    });
                });
            } else {
                // Display a single error message or a default message
                toast.error(error.response?.data?.message || 'Failed to purchase electricty subscription. Please try again.', {
                    autoClose: 5000,
                });
            }
        } finally {
            // Reset the submitting state regardless of success or failure
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className="card shadow-sm mb-4 p-3" style={{ borderRadius: '16px' }}>
                        {/* Header Section */}
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title" onClick={() => window.history.back()} style={{ cursor: 'pointer' }}>
                                <img src={flip} alt="Back" />
                            </h5>
                        </div>

                        {/* Review Section */}
                        <div className="p-2">
                            <h4 className="card-text">Review Payment</h4>
                            <p className="mb-4">Please review the details below</p>
                        </div>

                        {/* Card Body */}
                        <div className="card-body" style={{ border: '1px solid #E5E5E5', borderRadius: '10px' }}>
                            {/* Provider Information */}
                            <div className="p-1 mb-3" style={{ border: '1px solid #E5E5E5', borderRadius: '8px' }}>
                                <div className="row mx-auto align-items-center">
                                    <div className="col-auto">
                                        {/* <img src={mtn} alt="MTN Logo" /> */}
                                    </div>
                                    <div className="col">
                                        <h5 className='mb-0 mt-2 fw-bold'>Electricty Subscription Purchase</h5>
                                        <p>{codeName} Electricity Provider</p>
                                    </div>
                                </div>
                            </div>

                            {/* Details Section */}
                            <div className="mb-2">
                                <h6 className="text-muted">Type</h6>
                                <p className="fw-bold mb-2">{typeName}</p>
                            </div>
                            <div className="mb-2">
                                <h6 className="text-muted">Meter Number</h6>
                                <p className="fw-bold mb-2">{number}</p>
                            </div>
                            <div className="mb-2">
                                <h6 className="text-muted">Amount</h6>
                                <p className="fw-bold mb-2">{formatToNaira(amount)}</p>
                            </div>
                            <div className="mb-2">
                                <h6 className="text-muted">Electricity Provider</h6>
                                <p className="fw-bold mb-2">{codeName}</p>
                            </div>
                        </div>

                        {/* Footer Section */}
                        <div className="card-footer bg-transparent border-0">
                            <button
                                className="btn w-100 p-3"
                                style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '12px' }}
                                type="button"
                                onClick={handleProceed}
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pin Modal */}
            {showPinModal && (
                <PinModal
                    closePinModal={() => setShowPinModal(false)}
                    handleSubmitPin={handleSubmitPin}
                    isSubmitting={isSubmitting}
                />
            )}

            {/* Set Pin Modal */}
            {isSetPinModalVisible && (
                <SetPin
                    closeSetPinModal={() => setIsSetPinModalVisible(false)}
                    handleSubmitSetPin={fetchUserData}
                    isSubmitting={isSubmitting}
                />
            )}
        </div>
    );
};

export default ElectrictyReview;
