import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import flip from '../../assets/image/icons/flip-backward.png';
import FormInput from '../../components/Form/FormInput';
import { fetchCableCodes, verfiyCableNumber, fetchCablePlans } from '../../Service/Utility/CableService';


const Cable = () => {

  const [SmartCardNo, setSmartCardNo] = useState('');
  const [CableTV, setCableTV] = useState('');
  const [amount, setAmount] = useState('');
  const [Package, setPackage] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');
  const [cableTVCodes, setCableTVCodes] = useState([]);
  const [customer, setCustomer] = useState('');

  const [verificationStatus, setVerificationStatus] = useState('Unverified');
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  //fetch and display cable codes
  useEffect(() => {
    fetchCableCodes()
      .then(response => {
        // console.log('API response:', response);

        if (response.status === 'success' && Array.isArray(response.data)) {
          setCableTVCodes(response.data);
        } else {
          toast.error('Failed to fetch cable codes or data is not in the expected format');
          setCableTVCodes([]); // Ensure fallback to an empty array
        }
      })
      .catch(err => {
        console.error('Error fetching cable codes:', err);
        toast.error('An error occurred while fetching cable codes');
        setCableTVCodes([]); // Ensure fallback to an empty array
      });

    if (CableTV) {
      fetchCablePlans(CableTV)
        .then(response => {
          if (response.status === 'success' && Array.isArray(response.data)) {
            setPackage(response.data);
          } else {
            console.error('Unexpected response format:', response);
            toast.error('Invalid data format');
            setPackage([]); // Safeguard by setting an empty array
          }
        })
        .catch(err => {
          console.error('Error in fetchCablePlans:', err);
          toast.error('An error occurred while fetching cable plans');
          setPackage([]); // Handle errors by falling back to an empty array
        });
    }

  }, [CableTV]);



  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };


  // Function to verify cable number
  const handleVerify = async () => {
    const verfiydata = {
      SmartCardNo: SmartCardNo,
      CableTV: CableTV,
    };


    try {
      const response = await verfiyCableNumber(verfiydata); // Pass the data directly
      if (response.status === 'success') {
        setVerificationStatus('Verified'); // Update verification status
        setCustomer(response.data.customer_name)
        setIsVerified(true); // Set verified state to true
        toast.success(response.message);
      } else {
        toast.error('Verification failed. Please check your details.');
        setVerificationStatus('Unverified');
        setCustomer('');
        setIsVerified(false);
      }
    } catch (err) {
      console.error('Error verifying cable number:', err);
      const errorMessages = err.response?.data?.message || ['An error occurred during verification'];

      if (Array.isArray(errorMessages)) {
        errorMessages.forEach((msg) => {
          toast.error(msg, {
            autoClose: 5000,
          });
        });
      } else {
        // Display a single error message or a default message
        toast.error(err.response?.data?.message || 'Failed to Verfiy cable number. Please try again.', {
          autoClose: 5000,
        });
      }

      setVerificationStatus('Unverified'); // Reset status on error
      setIsVerified(false); // Reset verified state
    }
  };

  const validateInputs = () => {
    if (!SmartCardNo || !CableTV || !selectedPackage || !amount) {
      toast.error('Fill in the form fields.');
      return false;
    }
    return true;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      navigate('/review/cable', { //navigate to review page, with state containing data
        state: {
          number: SmartCardNo,
          amount,
          code: CableTV,
          Package: selectedPackage,
          customer,
        },
      });
    }
  };


  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-5">
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <div className='container'>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title" onClick={() => window.history.back()}>
                    <img src={flip} alt="flip-backward" />
                  </h5>
                </div>
                <h4 className="card-text mb-4">Cable subscription</h4>
              </div>

              {/* Form Section */}
              <form onSubmit={handleSubmit} >
                <div className='container'>
                  <FormInput
                    label="Select Cable"
                    id="CableTV"
                    type="select"
                    options={(cableTVCodes || []).map(code => ({
                      value: code.code,
                      label: code.name,
                    }))}
                    value={CableTV}
                    onChange={(e) => setCableTV(e.target.value)}
                    placeholder="Eg: DSTV"
                  />

                  <FormInput
                    label="Select Package"
                    id="Package"
                    type="select"
                    options={(Package || []).map(pkg => ({
                      value: pkg.PACKAGE_ID,
                      label: pkg.PACKAGE_NAME,
                    }))}
                    value={selectedPackage}
                    onChange={(e) => {
                      const selectedPlan = Package.find(pkg => pkg.PACKAGE_ID === e.target.value);
                      setSelectedPackage(e.target.value);
                      setAmount(selectedPlan?.PACKAGE_AMOUNT || '');
                    }}
                    placeholder="Eg: gotv-max"
                  />




                  {/* //hide the amount input, untill the meter number is Verified */}
                  {/* {isVerified && ( */}
                  <FormInput
                    label="Amount"
                    id="amount"
                    type="number"
                    value={amount}
                    onChange={handleInputChange(setAmount)}
                    placeholder="Enter amount"
                    readOnly={true}
                  />
                  {/* // )} */}

                  <FormInput
                    label="Cable Number"
                    id="MeterNo"
                    type="number"
                    value={SmartCardNo}
                    onChange={handleInputChange(setSmartCardNo)}
                    placeholder="Enter card Number"
                  />

                  {/* Verification button */}
                  <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn nlbtn mb-4"
                      id="verify-button"
                      style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '8px' }}
                      onClick={handleVerify} // Call verification function
                    >
                      Verify
                    </button>
                    <span className={`fw-bold ${isVerified ? 'text-success' : 'text-danger'}`} id='verification-status'>
                      {verificationStatus}
                    </span>
                    {/* Show verification status */}
                  </div>

                  {isVerified && (
                    <p className='fw-bold border p-2 rounded'>Customer Name: <span>{customer || 'Not set'}</span></p>
                  )}

                  <button
                    onClick={handleSubmit}
                    className="btn w-100 nlbtn p-3"
                    disabled={!isVerified} // Disable button until verified
                    style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '16px' }}
                    type="submit"
                  >
                    Continue
                  </button>


                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cable
