import React, { useEffect, useState } from 'react';
import { formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';
import FormInput from '../../components/Form/FormInput';
import flip from '../../assets/image/icons/flip-backward.png';
import { toast } from 'react-toastify';
import PaymentProvider from '../../components/Transaction/PaymentProvider';
import { deposit } from '../../Service/Transaction/TransactionService';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../Service/GetUser/UserService';

const Deposit = () => {
    const navigate = useNavigate();
    const [bal, setBal] = useState('---');
    const [amount, setAmount] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getUser();

                if (response.status === 'success') {
                    setBal(response.customer.ngnbalance);
                } else {
                    throw new Error(response.message || 'Unexpected response');
                }
            } catch (error) {
                console.error('Failed to fetch user data:', error);

                const errorMessages = error.response?.data?.message;
                if (Array.isArray(errorMessages)) {
                    errorMessages.forEach((msg) => {
                        toast.error(msg, {
                            autoClose: 5000,
                        });
                    });
                } else {
                    // Display a single error message or a default message
                    toast.error(
                        error.response?.data?.message || 'Failed to get user data. Please try again.',
                        {
                            autoClose: 5000,
                        }
                    );
                }
            }
        };

        fetchUserData();
    })

    const handleContinue = async (e) => {
        e.preventDefault();
        const parsedNumber = parseFloat(amount);
        const payload = {
            "amount": parsedNumber,
            "payfrom": 2,
        };

        if (!isNaN(parsedNumber) && parsedNumber > 0.9) {
            setIsSubmitting(true);

            try {
                const response = await deposit(payload);

                if (response.status === 'success') {
                    toast.success(response.message, {
                        autoClose: 3000,
                    });
                    if (response.paymentrequest.responseBody.checkoutUrl) {
                        window.location.href = response.paymentrequest.responseBody.checkoutUrl;
                    } else {
                        toast.error('Payment URL missing.', {
                            autoClose: 3000,
                        });
                    }
                } else {
                    throw new Error(response.message || 'Unexpected response');
                }
            } catch (error) {
                console.error('Failed to deposit:', error);

                const errorMessages = error.response?.data?.message;
                if (Array.isArray(errorMessages)) {
                    errorMessages.forEach((msg) => {
                        toast.error(msg, {
                            autoClose: 5000,
                        });
                    });
                } else {
                    toast.error(error.response?.data?.message || 'Failed to deposit. Please try again.', {
                        autoClose: 5000,
                    });
                }
            } finally {
                setIsSubmitting(false);
            }
        } else {
            toast.error('Please enter a valid amount.', {
                autoClose: 3000,
            });
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    {/* Back Button */}
                    <div className='d-flex align-items-center px-0'>
                        <h5 className="card-title me-2" onClick={() => window.history.back()}>
                            <img src={flip} alt='flip-backward' />
                        </h5>
                        <div className="text-black fw-normal fs-5">
                            Deposit
                        </div>
                    </div>

                    <form className='mt-4' onSubmit={handleContinue}>
                        <FormInput
                            label="Amount"
                            type="text"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Enter amount"
                        />
                        <small className='text-grey fs-c7'>
                            Current amount
                            <span className='badge bg-success-dim1 text-success rounded p-2 ms-1'>
                                N{formatNumberWithCommas(bal)}
                            </span>
                        </small>

                        <button
                            type="submit"
                            className='btn btn-primary border-0 w-100 py-2 mt-2'
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Continue"
                            )}
                        </button>

                        <PaymentProvider />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Deposit;
