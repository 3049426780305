import React, { useEffect, useState } from 'react';
import './Transaction.css';
import { Link, useNavigate } from 'react-router-dom';
import { Loader, encryptData, formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';
import Cookies from 'js-cookie';


const TransactionCard = ({ tid, ttype, twhich, tclasstype, description, amount, date, details, tstatus }) => {
  const navigate = useNavigate();

  const getIconName = () => {
    if (twhich === '1') {
      switch (tclasstype) {
        case '1':
          return 'fa-plus-circle';
        case '2':
          return 'fa-minus-square';
        default:
          return 'fa-plus-circle';
      }
    } else if (twhich === '2') {
      switch (tclasstype) {
        case '1':
          return 'fa-credit-card';
        case '2':
          return 'fa-database';
        case '3':
          return 'fa-ethernet';
        case '4':
          return 'fa-bolt';
        default:
          return 'fa-plus-circle';
      }
    } else if (twhich === '3') {
      switch (tclasstype) {
        case '1':
          return 'fa-truck-loading';
        case '2':
          return 'fa-pallet';
        default:
          return 'fa-plus-circle';
      }
    } else {
      return 'fa-plus-circle';
    }
  };

  const getIconColor = () => {
    if (twhich === '1') {
      return 't-icon-holder1';
    } else if (twhich === '2') {
      return 't-icon-holder2';
    } else if (twhich === '3') {
      return 't-icon-holder3';
    } else {
      return 't-icon-holder1';
    }
  };

  const getListTitle = () => {
    if (twhich === '1') {
      switch (tclasstype) {
        case '1':
          return 'Deposit';
        case '2':
          return 'Withdrawal';
        default:
          return 'TXN';
      }
    } else if (twhich === '2') {
      switch (tclasstype) {
        case '1':
          return 'Airtime';
        case '2':
          return 'VTU Data';
        case '3':
          return 'Cable/TV';
        case '4':
          return 'Electricity';
        default:
          return 'TXN';
      }
    } else if (twhich === '3') {
      switch (tclasstype) {
        case '1':
          return 'Investment';
        case '2':
          return 'Payout';
        default:
          return 'TXN';
      }
    } else {
      return 'TXN';
    }
  };

  const getBadgeColor = () => {
    if (twhich === '1') {
      switch (tclasstype) {
        case '1':
          return 'text-bg-success';
        case '2':
          return 'text-bg-danger';
        default:
          return 'text-bg-primary';
      }
    } else if (twhich === '2') {
      return 'text-bg-danger';
    } else if (twhich === '3') {
      switch (tclasstype) {
        case '1':
          return 'text-bg-danger';
        case '2':
          return 'text-bg-success';
        default:
          return 'text-bg-primary';
      }
    } else {
      return 'text-bg-primary';
    }
  };

  const storeTxnData = () => {
    const encryptedID = encryptData(tid, 'txnid');
    const transactionData = {
      tid: encryptedID,
      ttype,
      twhich,
      tclasstype,
      description,
      tstatus,
      amount,
      date,
      details,
      iconName: getIconName(),
      iconColor: getIconColor(),
      listTitle: getListTitle(),
      badgeColor: getBadgeColor(),
    };
    navigate('/transaction-details', { state: transactionData });
  };

  return (
    <li className="d-flex mb-2 t-card" onClick={() => storeTxnData(tid)}>
      <div className={`flex-shrink-0 me-3 t-icon-holder ` + getIconColor()}>
        <span><i className={`fa ${getIconName()}`}></i></span>
      </div>
      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
        <div className="me-2" style={{ width: '55%' }}>
          <h6 className="mb-1" style={{ fontSize: '14px' }}>{getListTitle()}</h6>
          <small className="text-muted d-block" style={{ fontSize: '7px', fontWeight: '700' }}>{description}</small>
        </div>
        <div className="user-progress  t-sholder">
          <h6 className="fw-bold t-amount" style={{ fontSize: '13px', color: ttype === '1' ? 'green' : '#b74a4a' }}>
            {(ttype === '1' ? '+' : '-') + `N${formatNumberWithCommas(amount)}`}
          </h6>
          <span className="t-date">{date}</span>
        </div>
      </div>
    </li>
  );
};

export default TransactionCard;