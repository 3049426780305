import axios from 'axios';
import Cookies from 'js-cookie';


const uRL = process.env.REACT_APP_URL;

export const fetchInvestmentPlan = async () => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.get(`${uRL}customer/fetchplans`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        // Handle the response accordingly
        if (response.status === 200) {
            return response.data; // success response
        } else {
            throw new Error('Failed to get gavice plans');
        }
    } catch (error) {
        console.error('Error fetching gavice plans', error);
        throw error; // re-throw the error for further handling
    }
};


export const createInvestment = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/investment/createinvestment`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to process airtime purchase');
        }
    } catch (error) {
        console.error('Error purchasing airtime:', error);
        throw error; // re-throw the error for further handling
    }

}

export const getUserInvestments = async () => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.get(`${uRL}customer/investment/fetchall`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to fetch user investments');
        }
    } catch (error) {
        console.error('Error fetching user investments:', error);
        throw error;
    }

}

export const getAllUserInvestmentDetails = async () => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.get(`${uRL}customer/dashboard/getallinvestmentdetails`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 200) {
        

            return response.data;

        } else {
            throw new Error('Failed to fetch user investments');
        }
    } catch (error) {
        console.error('Error fetching user investments:', error);
        throw error;
    }

}

export const getTotalInvestmentCount = async () => {
    const token = Cookies.get('userAuth');

    try {

        const response = await axios.get(`${uRL}customer/dashboard/allinvestments`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            console.log(response);
            return response.data;

        } else {
            throw new Error('Failed to fetch user investments count');
        }
    } catch (error) {
        console.error('Error fetching user investments:', error);
        throw error;
    }

}

export const cashoutInvestmentReturns = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/investment/cashoutreturns`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to process cashout return');
        }
    } catch (error) {
        console.error('Error processing cashout return:', error);
        throw error; // re-throw the error for further handling
    }

}