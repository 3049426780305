import React from 'react';
import { Link } from 'react-router-dom';

const UtilityCard = ({ title, image, link, isAirtime }) => {

    return (
        <div className="col-6 col-sm-4 col-md-4 mb-4 p-6">
            <Link to={link}>
                <div className={isAirtime ? "card shadow-sm" : ""}>
                    <div className="d-flex justify-content-center mt-1 mb-3">
                        <img src={image} alt={title} />
                    </div>
                    <h5 className="text-center" style={{ fontSize: '1rem' }}>
                        {title}
                    </h5>
                </div>
            </Link>
        </div>
    );
};

export default UtilityCard;
