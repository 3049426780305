import { TbArrowBackUp } from "react-icons/tb";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../../utilities/axios";
import { AuthContext } from "./context_api/AuthContext";

const OtpPasswordReset = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // const email = location.state?.email;
  const { setAuthStage } = useContext(AuthContext);
  const [email, setEmail] = useState(
    location.state?.email || localStorage.getItem("email")
  );

  // Store email in localStorage when component mounts
  useEffect(() => {
    if (email) {
      localStorage.setItem("email", email);
    }
  }, [email]);

  const handleChange = (element, index) => {
    const value = element.value;

    if (!isNaN(value) && value.length === 1) {
      setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);

      if (element.nextSibling) {
        element.nextSibling.focus(); // Move to the next input
      }
    }
  };

  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      if (element.target.previousSibling) {
        element.target.previousSibling.focus(); // Move to the previous input
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp.includes("")) {
      // toast.error("input field required");
      setError(true);
      return;
    }

    setIsLoading(true);
    const otpCode = otp.join("");

    try {
      const response = await customFetch.post("customer/auth/checkotp", {
        email,
        otp: otpCode,
      });

      if (response.status === 200) {
        toast.success(response?.data?.message || "OTP verified successfully!");
        navigate("/forgot-password/otp/reset-password", {
          state: { email, otp: otpCode },
        });
        setAuthStage(3);
      } else {
        throw new Error(response.data.message || "Please enter a valid OTP");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Incorrect OTP");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Remove email from localStorage on unmount
    return () => {
      localStorage.removeItem("email");
    };
  }, []);

  const handleResubmit = async () => {
    try {
      const response = await customFetch.post(
        "customer/auth/forgotpasswordwithemail",
        {
          email,
        }
      );

      toast.success(response?.data?.message || "OTP resent to your email!");
      navigate(location.pathname, { state: { email } });
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Error in sending OTP");
      if (error?.response?.status === 422 || error.message) {
        toast.error(error?.message || "Bad Request");
      }
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <button
                className="navigate-btn mb-10"
                onClick={() => navigate(-1)}
              >
                <TbArrowBackUp />
              </button>

              <div className="d-flex flex-column otp-container padding mt">
                <h5>Verify it's you</h5>
                <p>
                  Please enter the 6-digit OTP code sent to your mail
                  <span className="text-muted">({email})</span>.
                </p>
                <label htmlFor="" className="form-label capitalize">
                  OTP Code
                </label>
                <div className="d-flex justify-content-center">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type="text"
                      placeholder="-"
                      className="form-control otp-input"
                      maxLength="1"
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onFocus={(e) => e.target.select()}
                      required
                    />
                  ))}
                </div>

                {error && (
                  <p className="text-danger mt-2">
                    Please fill in all OTP fields.
                  </p>
                )}

                <button
                  className="btn btn-primary continue-btn mt-3"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? "submitting..." : "Continue"}
                </button>

                <p className="mt-4 d-flex justify-content-center">
                  Didn’t receive an email?{" "}
                  <Link onClick={handleResubmit} className="resend-link">
                    Resend
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPasswordReset;
