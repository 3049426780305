import React from 'react';

const Plankard = ({ icon, iconColor, title, value }) => {
  return (
    <div className="col-4 mb-4" style={{ padding: '0 5px' }}>
      <div className="card">
        <div className="card-body d-flex flex-column align-items-center justify-content-center text-center">
          <div className="card-title mb-2">
            <i className={`fa ${icon}`} style={{ color: iconColor, fontSize: '30px' }}></i>
          </div>

          <span
            className="fw-semibold d-block mb-1"
            style={{ fontSize: '9.6px', fontWeight: '600' }}
          >
            {title}
          </span>

          <h4
            className="card-title mb-2"
            style={{ fontSize: '12px', fontWeight: '900' }}
          >
            {value}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Plankard;
