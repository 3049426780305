import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import flip from '../../assets/image/icons/flip-backward.png';
import FormInput from '../../components/Form/FormInput';

const Airtime = () => {
    const isLargeScreen = window.innerWidth >= 992; // large breakpoint
    const [network, setNetwork] = useState('');
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('');
    const navigate = useNavigate(); // For page navigation

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const validateInputs = () => {
        if (!network || !phone || !amount) {
            toast.error('Please fill all fields.');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateInputs()) {

            // Navigate to the AirtimeReview page with state containing form data
            navigate('/review/airtime', { state: { number: phone, amount, biller: network } });
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className="card shadow-sm mb-4" style={{ height: isLargeScreen ? '120%' : '100%' }}>
                        <div className="card-body">
                        <div className='container'>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5 className="card-title" onClick={() => window.history.back()}>
                                    <img src={flip} alt="flip-backward" />
                                </h5>
                            </div>
                            <h4 className="card-text mb-4">Buy Airtime</h4>
                            </div>

                            {/* Form Section */}
                            <form onSubmit={handleSubmit}>
                                <div className="container">
                                    <FormInput
                                        label="Network"
                                        id="network"
                                        type="select"
                                        options={[
                                            { value: '01', label: 'MTN' },
                                            { value: '02', label: 'GLO' },
                                            { value: '03', label: '9MOBILE' },
                                            { value: '04', label: 'AIRTEL' }
                                        ]}
                                        value={network}
                                        onChange={handleInputChange(setNetwork)}
                                        placeholder="Select Network"

                                    />

                                    <FormInput
                                        label="Phone Number"
                                        id="phone"
                                        type="text"
                                        value={phone}
                                        onChange={handleInputChange(setPhone)}
                                        placeholder="Enter phone number"
                                    />
                                    <FormInput
                                        label="Amount"
                                        id="amount"
                                        type="text"
                                        value={amount}
                                        onChange={handleInputChange(setAmount)}
                                        placeholder="Enter amount"
                                    />

                                    <button
                                        className="btn w-100 nlbtn p-3"
                                        style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '16px' }}
                                        type="submit"
                                    >
                                        Continue                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default Airtime;
