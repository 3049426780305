import $ from 'jquery';
// Import DataTables scripts dynamically
import('datatables.net-dt/css/jquery.dataTables.css');
import('datatables.net');


export function handleCustomerRoute() {
    // Your code here
    $(function() {
        alert('Customer route found. Executing function...');
        console.log($('.datatable tr').length);
    })

}

export function handleDataTable() {
    $(function() {
		console.log($('.datatable tr').length-1);
        if($('.datatable tr').length-1 > 0) {
            // Destroy existing DataTable
            setTimeout(()=>{
                if ($.fn.DataTable.isDataTable('.datatable')) {
                    $('.datatable').DataTable().destroy();
                }
                // Initialize a new DataTable
                $('.datatable').DataTable({
                    "searching": true, // Enable search functionality
                    "bFilter": false,
                    "sDom": 'fBtlpi',  
                    "ordering": true,
                    
                    "language": {
                        search: ' ',
                        sLengthMenu: '_MENU_',
                        paginate: {
                            next: 'Next <i class=" fa fa-angle-double-right ms-2"></i>',
                            previous: '<i class="fa fa-angle-double-left me-2"></i> Previous'
                        },
                        },
                    initComplete: (settings, json)=>{
                        $('.dataTables_filter').appendTo('#tableSearch');
                        $('.dataTables_filter input').attr('placeholder', 'Search'); // Add placeholder
                        $('.dataTables_filter').css('margin-bottom', '15px'); // Add margin-bottom
                    },	
                });
            },1000)
        }
		
    })
    
}
