import React, { useEffect, useState } from 'react'
import flip from '../../assets/image/icons/flip-backward.png';
import WhatsAppButton from '../../components/Button/WhatsAppButton';

const CustomerSupportPage = () => {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className='container'>
                        {/* Back Button and Header */}
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title" onClick={() => window.history.back()}>
                                <img src={flip} alt="flip-backward" style={{ cursor: 'pointer' }} />
                            </h5>
                        </div>
                        <h4 className="text-dark mb-4">Customer Service</h4>

                        {/* customer service Page Content */}
                        <div className="bg-white p-4 shadow-sm rounded">
                            <h5>Contact us via email or whatsapp</h5>
                            {/*  Email */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Email</span>
                                <a href="mailto:logistics@gavice.com">
                                    <span className="text-dark">logistics@gavice.com</span>
                                </a>

                            </div>

                            {/* number */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Phone Number</span>
                                <span className="text-primary">+2348137519848</span>
                            </div>

                           <WhatsAppButton />


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerSupportPage
