import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AbbreviateNumber, formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';


const InvestmentCard = ({ plan }) => {
    const navigate = useNavigate();

    const investmentTypeImgMap = {
        "1": `https://gaviceglobal.com/gimg/plan-icons/bike.png`,
        "2": `https://gaviceglobal.com/gimg/plan-icons/minibus.png`,
        "3": `https://gaviceglobal.com/gimg/plan-icons/jetmover.png`,
        "4": `https://gaviceglobal.com/gimg/plan-icons/truck.png`,
    };

    // Determine investment type based on the plan's investment type value
    const investmentImgType = investmentTypeImgMap[plan.category];


    return (
        <div
            className="card shadow-sm mb-3 cursor-pointer"
            key={plan.id}
            style={{
                width: '100%',
                margin: '0 auto',
                padding: '10px',
            }}
            onClick={() => navigate('/investment/details', { state: { plan } })}
        >
            <div className="d-flex align-items-center">
                <div
                    className="rounded-circle bg-primary-dim2 d-flex align-items-center justify-content-center"
                    style={{
                        overflow: 'hidden',
                        width: '70px',
                        height: '70px',
                        padding: '5px',
                        flexShrink: 0,
                    }}
                >
                    <img
                        src={investmentImgType}
                        alt="Plan"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            borderRadius: '50%',
                        }}
                    />
                </div>

                <div className="p-2" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <h5 className="card-title fs-6 fw-bold mb-0">{plan.name}</h5>
                        </div>
                        <span
                            className={`badge 
                                ${plan.status !== '1' ? 'bg-danger-dim1 text-danger' : 'bg-success-dim1 text-success-dim1'} 
                                text-capitalize fs-c8 align-content-center
                            `}
                        >
                            {plan.status !== '1' ? 'Sold out' : 'invest Now'}
                        </span>
                    </div>

                    <div className="d-flex">
                        <p className="card-text fs-c7 mb-0 pe-1 text-success">{plan.percentage}%</p>
                        <p className="card-text fs-c7 mb-0">return in {plan.no_of} {plan.duration=='1'?'Weeks':"Months"}</p>
                    </div>

                    <div className="d-flex justify-content-start align-items-center">
                        <div className="fs-c8 pe-5">
                            <small className="fw-800 d-block">₦{AbbreviateNumber(plan.amount)}</small>

                            <small>per unit</small>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvestmentCard;
