import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Confirm,
  Details,
  ForgotPassword,
  Login,
  Otp,
  Register,
  Time,
  ResetPassword,
  OtpPasswordReset,
} from "./pages/Auth";
import { AuthProvider } from "./pages/Auth/context_api/AuthContext";
import ProtectedRoute from "./pages/Auth/context_api/ProtectRouteProvider";
import { TimeoutProvider } from "./pages/Auth/context_api/TimeContext";
import AddToHomeInstruction from "./pages/Instruction/AddToHomeInstruction";

const AuthRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/addtohomeinstruction" element={<AddToHomeInstruction />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/register"
          element={
            <TimeoutProvider>
              <Register />
            </TimeoutProvider>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <TimeoutProvider>
              <ForgotPassword />
            </TimeoutProvider>
          }
        />

        {/* Routes with TimeoutProvider */}

        <Route
          path="/register/otp"
          element={
            <TimeoutProvider>
              <ProtectedRoute requiredStage={2} redirectTo="/login">
                <Otp />
              </ProtectedRoute>
            </TimeoutProvider>
          }
        />
        <Route
          path="/register/otp/details"
          element={
            <TimeoutProvider>
              <ProtectedRoute requiredStage={3} redirectTo="/login">
                <Details />
              </ProtectedRoute>
            </TimeoutProvider>
          }
        />
        <Route
          path="/register/otp/details/time"
          element={
            <TimeoutProvider>
              <ProtectedRoute requiredStage={4} redirectTo="/login">
                <Time />
              </ProtectedRoute>
            </TimeoutProvider>
          }
        />
        <Route
          path="/register/otp/details/time/confirm-registration"
          element={
            <TimeoutProvider>
              <ProtectedRoute requiredStage={5} redirectTo="/login">
                <Confirm />
              </ProtectedRoute>
            </TimeoutProvider>
          }
        />

        {/* Password Reset Flow */}

        <Route
          path="/forgot-password/otp"
          element={
            <TimeoutProvider>
              <ProtectedRoute requiredStage={2} redirectTo="/login">
                <OtpPasswordReset />
              </ProtectedRoute>
            </TimeoutProvider>
          }
        />
        <Route
          path="/forgot-password/otp/reset-password"
          element={
            <TimeoutProvider>
              <ProtectedRoute requiredStage={3} redirectTo="/login">
                <ResetPassword />
              </ProtectedRoute>
            </TimeoutProvider>
          }
        />

        {/* Redirect for unknown routes */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </AuthProvider>
  );
};

export default AuthRoutes;
