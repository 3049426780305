import { useState, useEffect, useContext } from "react";
import { TbArrowBackUp } from "react-icons/tb";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import customFetch from "../../utilities/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "./context_api/AuthContext";

const TimeConfirmation = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [confirmTime, setConfirmTime] = useState("");
  const [timeFrames, setTimeFrames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  // console.log(location.state);
  const { setAuthStage } = useContext(AuthContext);

  useEffect(() => {
    const fetchTimeFrames = async () => {
      try {
        const response = await customFetch("customer/auth/fetchctimes");
        // console.log(response);
        const data = response.data;
        // console.log(data);
        setTimeFrames(data);
      } catch (error) {
        console.log(error);
        toast.error("Failed to load time frames.");
      }
    };
    fetchTimeFrames();
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleTimeSelect = (time) => {
    setConfirmTime(time);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  const handleSubmit = async () => {
    if (!confirmTime) {
      toast.error("Please select a time frame.");
      return;
    }
    setIsLoading(true);
    try {
      navigate("/register/otp/details/time/confirm-registration", {
        state: { ...state, confirmTime },
      });
      setAuthStage(5);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              {/* Navigate back */}

              <button
                className="navigate-btn mb-10"
                onClick={() => navigate(-1)}
              >
                <TbArrowBackUp />
              </button>

              <div className="d-flex flex-column time-container mt">
                <h5>Account Confirmation</h5>
                <p>
                  Please select your preferred time frame to receive a call with
                  our team within 24 hours.
                </p>
                {/* Time Selection Input */}
                <div className="mb-3 position-relative">
                  <label htmlFor="timeSelect" className="form-label capitalize">
                    Time
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      readOnly
                      name="confirmedtime"
                      className="form-control"
                      placeholder="Select a time frame (GMT +1)"
                      value={confirmTime}
                      onClick={toggleDropdown}
                      required
                    />
                    <span
                      className="position-absolute"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={toggleDropdown}
                    >
                      {dropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                  </div>
                  {dropdownOpen && (
                    <ul
                      className="dropdown-menu show position-absolute w-100"
                      style={{ top: "100%", zIndex: 1 }}
                    >
                      {timeFrames.map(({ name, id }) => (
                        <li key={id} onClick={() => handleTimeSelect(name)}>
                          <button className="dropdown-item">{name}</button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Continue Button */}
                <button
                  className="btn btn-primary w-100 continue-btn mt-3"
                  onClick={handleSubmit}
                  disabled={isLoading || !confirmTime}
                >
                  {isLoading ? "Submitting..." : "Continue"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeConfirmation;
