import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import FormInput from '../Form/FormInput';


const ChangePasswordModal = ({ closeChangePasswordModal, handleSubmitChangePassword, isSubmitting }) => {
    const [oldpassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        handleSubmitChangePassword(oldpassword, password);

    };

    return (
        <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Change Password</h5>
                        <button type="button" className="btn-close" onClick={closeChangePasswordModal}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <FormInput
                                label="Old Password"
                                id="oldpassword"
                                type="password"
                                value={oldpassword}
                                onChange={handleInputChange(setOldPassword)}
                                placeholder="*****"
                            />

                            <FormInput
                                label="New Password"
                                id="password"
                                type="passsword"
                                value={password}
                                onChange={handleInputChange(setPassword)}
                                placeholder="*****"
                            />

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closeChangePasswordModal} disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordModal;


