import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../../utilities/axios";
import { TbArrowBackUp } from "react-icons/tb";
import { AuthContext } from "./context_api/AuthContext";

const ResetPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { otp, email } = useLocation().state;
  // console.log(useLocation().state);
  const { setAuthStage } = useContext(AuthContext);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      toast.error("All fields are required");
      return;
    }
    if (confirmPassword !== password) {
      setError(true);
      return;
    }

    setIsLoading(true);
    try {
      const response = await customFetch.post("customer/auth/resetpassword", {
        otp,
        email,
        password,
        password_confirmation: confirmPassword,
      });

      // if (response.status === 201)

      toast.success("Password reset successful");
      navigate("/login");
      setAuthStage(0);
    } catch (error) {
      console.log(error.response); // Log error response from server
      toast.error(
        error.response?.data?.message ||
          error.message ||
          "An error occurred during registration"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
                {/* Navigate back */}
                <button className="navigate-btn" onClick={() => navigate(-1)}>
                  <TbArrowBackUp />
                </button>
                <h5
                  className="mb-10"
                  style={{
                    transform: "translateY(-80%)",
                  }}
                >
                  Reset password
                </h5>

                {/* <!--  --> */}
                <div className="d-flex flex-column register-container padding mt">
                  <h4 className="mb-2">Set a new password</h4>
                  <p className="mb-4">
                    Code verified successfully. Set a new password.
                  </p>
                  <form id="userLogin" onSubmit={onSubmit} className="mb-4">
                    <div className="mb-3 form-password-toggle">
                      <label
                        className="form-label capitalize"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          id="password"
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          aria-describedby="password"
                          minLength={8}
                          required
                        />
                        <span className="input-group-text cursor-pointer">
                          <i
                            className={`bx ${
                              !passwordVisible ? "bx-hide" : "bx-show"
                            } toggle-password`}
                            onClick={togglePasswordVisibility}
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <label
                        className="form-label capitalize"
                        htmlFor="password"
                      >
                        Confirm Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          id="password"
                          className="form-control"
                          name="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          aria-describedby="password"
                          minLength={8}
                          required
                        />
                        <span className="input-group-text cursor-pointer">
                          <i
                            className={`bx ${
                              !passwordVisible ? "bx-hide" : "bx-show"
                            } toggle-password`}
                            onClick={togglePasswordVisibility}
                          ></i>
                        </span>
                      </div>
                    </div>
                    {error && (
                      <p className="text-danger mt-2">
                        The password does not match.
                      </p>
                    )}
                    <button
                      className="btn continue-btn btn-primary mt-3 w-100"
                      type="submit"
                      disabled={isLoading || !password || !confirmPassword}
                    >
                      {isLoading ? "Updating..." : "Update Password"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
