import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import customFetch from "../../utilities/axios";
import { TbArrowBackUp } from "react-icons/tb";
import { AuthContext } from "./context_api/AuthContext";

const ConfirmPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    otp,
    email,
    phoneNumber,
    firstName,
    lastName,
    location,
    address,
    city,
    confirmTime,
  } = useLocation().state;
  const { setAuthStage } = useContext(AuthContext);

  // console.log(useLocation().state);

  const handleRegister = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch.post("customer/auth/register", {
        otp,
        email,
        phonenumber: phoneNumber,
        firstname: firstName,
        lastname: lastName,
        location,
        address,
        city,
        confirmedtime: confirmTime,
      });

      // if (response.status === 201)

      toast.success("Account created successfully");
      navigate("/login");
      setAuthStage(0);
    } catch (error) {
      console.log(error.response); // Log error response from server
      toast.error(
        error.response?.data?.message ||
        error.message ||
        "An error occurred during registration"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
                {/* Navigate back */}
                <button
                  className="navigate-btn mb-10"
                  onClick={() => navigate(-1)}
                >
                  <TbArrowBackUp />
                </button>
                <div className="d-flex flex-column text-center mt">
                  <h5>Call schedule confirmed</h5>
                  <p>
                    You will receive a call from our team. Check your
                    <br />
                    status after the call for account confirmation
                  </p>

                  <button
                    className={`btn continue-btn btn-primary mt-2 w-100 ${isLoading ? "loading-btn" : ""}`}
                    type="submit"
                    onClick={handleRegister}
                    disabled={isLoading}
                    style={{ color: isLoading ? "black" : "white" }} // Conditionally set text color
                  >
                    {isLoading ? "Processing..." : "Sign In"}
                  </button>


                  {/* <button
                    className="btn continue-btn btn-primary mt-2 w-100"
                    type="submit"
                    onClick={handleRegister}
                    disabled={isLoading}
                  >
                    Sign In
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPage;
