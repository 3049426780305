import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import $ from 'jquery'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader } from '../../utilities/reusablefunctions_variables';
import { toast } from 'react-toastify';
import Navbar from '../Navbar/Navbar';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Header2 from '../Header/Header2';

const Layout = () => {
    const isMobile = useMediaQuery({ maxWidth: 992 });
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Function to toggle the class
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');
    const [loading, setLoading] = useState(true);

  useEffect(() => {
  }, []);


  return (
    <div>
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
            <div className="layout-container">
                {/* <!-- Navbar --> */}
                <Navbar menuOpen={isMenuOpen} toggleMenu={toggleMenu}/>
                {/* <!-- / Navbar -->
                <!-- Layout container --> */}
                <div className="layout-page">
                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Menu --> */}
                        {isMobile ? <Header2 menuOpen={isMenuOpen} toggleMenu={toggleMenu}/> : <Header/>}
                        {/* <!-- / Menu -->
                        <!-- Content --> */}
                        <Outlet/>
                        {/* <!--/ Content --> */}
                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}
                        <div className="content-backdrop fade"></div>
                    </div>
                    {/* <!--/ Content wrapper --> */}
                </div>
                {/* <!--/ Layout container --> */}
            </div>
        </div>
        {/* <!-- Overlay --> */}
        <div className="layout-overlay layout-menu-toggle"></div>
        {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
        <div className="drag-target"></div>
        {/* <!--/ Layout wrapper --> */}
    </div>
  )
}

export default Layout