/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
    <div className="container-xxl d-flex flex-wrap justify-content-center py-2 flex-md-row flex-column">
        <div className="mb-2 mb-md-0 text-center">
            © 2024
             Powered by <a href="https://gavice.com" target="_blank" rel="noopener noreferrer" className="footer-link fw-bolder">Gavice</a>
        </div>
        
    </div>
    </footer>
  )
}

export default Footer