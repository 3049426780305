import Carousel from 'react-multi-carousel';
import BikeImg from '../../otherassets/plan-icon/bike.png';
import MinibusImg from '../../otherassets/plan-icon/minibus.png';
import JetmoverImg from '../../otherassets/plan-icon/jetmover.png';
import TruckImg from '../../otherassets/plan-icon/truck.png';
import { Loader, encryptData, formatDate, formatNumberWithCommas, formatOnlyDate, AbbreviateNumber } from '../../utilities/reusablefunctions_variables';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { fetchInvestmentPlan } from '../../Service/Investment/InvestmentService';

const responsive = {
    superLargeDesktop: {
        // screens larger than 1600px
        breakpoint: { max: 4000, min: 1600 },
        items: 3
    },
    desktop: {
        // screens between 1024px and 1600px
        breakpoint: { max: 1600, min: 1024 },
        items: 1.5
    },
    tablet: {
        // screens between 768px and 1024px
        breakpoint: { max: 1024, min: 768 },
        items: 2
    },
    mobile: {
        // screens less than 768px
        breakpoint: { max: 768, min: 0 },
        items: 1
    }
};

const CustomLeftArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="custom-arrow"
            style={{
                background: 'none',
                border: 'none',
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '20px',
                height: '20px',
                cursor: 'pointer'
            }}
        >
            {""}
        </button>
    );
};

const CustomRightArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="custom-arrow"
            style={{
                background: 'none',
                border: 'none',
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '20px',
                height: '20px',
                cursor: 'pointer'
            }}
        >
           {""}
        </button>
    );
};



const InvestmentCarousel = () => {
    const navigate = useNavigate();
    const [investmentPlans, setInvestmentPlans] = useState([]);
    
    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await fetchInvestmentPlan();
                setInvestmentPlans(response.data);
            } catch (error) {
                console.error('Failed to fetch carousel data:', error);
                setInvestmentPlans([]);
                
                const errorMessages = error.response?.data?.message;
                if (Array.isArray(errorMessages)) {
                    errorMessages.forEach((msg) => {
                        toast.error(msg, {
                            autoClose: 5000,
                        });
                    });
                } else {
                    // Display a single error message or a default message
                    toast.error(
                        error.response?.data?.message || 'Failed to fetch carousel dta. Please try again.',
                        {
                            autoClose: 5000,
                        }
                    );
                }
            }
        };

        fetchPlans();
    }, []);
   

    return (
        <Carousel 
            responsive={responsive}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            autoPlay={true} 
            autoPlaySpeed={4500} 
            infinite={true}
            showDots={false}
        >
            {investmentPlans.map((plan) => (
                <div 
                    className="card shadow-sm" 
                    key={plan.id} 
                    style={{ 
                        width: '20rem', 
                        margin: '0 0 2px',
                        padding: '4px 10px'
                    }}
                >
                    <div className="d-flex align-items-center">
                        <div
                            className="rounded-circle bg-primary-dim2 d-flex align-items-center justify-content-center"
                            style={{
                                overflow: 'hidden',
                                width: '70px',
                                height: '70px',
                                padding: '5px',
                                flexShrink: 0,
                            }}
                        >
                            <img
                                src={plan.which=='1'? BikeImg:plan.which=='2'?MinibusImg:plan.which=='3'?JetmoverImg:plan.which=='4'?TruckImg:''}
                                alt='Plan'
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    borderRadius: '50%',
                                }}
                            />
                        </div>

                        <div className="p-3" style={{ width: '70%' }}>
                            <h6>
                                <span className={`
                                    badge
                                    bg-${plan.status === '1' ? 'success-dim1' : 'danger'} 
                                    text-${plan.status === '1' ? 'success-dim1' : 'danger'} 
                                    text-capitalize fs-c8
                                `}>
                                    {plan.status === '1' ? 'Active' : 'Inactive'}
                                </span>
                            </h6>
                            <h5 className="card-title fs-c7">{plan.name}</h5>
                            <p className="card-text fs-c8 mb-1">Invest ₦{AbbreviateNumber(plan.amount)} for a {plan.percentage}% in {plan.no_of} {plan.duration=='1'?'Weeks':"Months"}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <button className="btn btn-sm btn-blue"
                                   // onClick={() => navigate(`/investmentdetails/${plan.id}`)}
                                    onClick={() => navigate('/investment/details', { state: { plan } })}
                                >
                                    Invest
                                </button>
                                <div className='fs-c8'>
                                    <small>N{AbbreviateNumber(plan.amount)}/unit</small> <br />
                                    <small>{AbbreviateNumber(plan.returns)} {plan.duration==='1'?'Weekly':"Monthly"}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default InvestmentCarousel;
