import React, { useEffect, useState } from 'react'
import flip from '../../assets/image/icons/flip-backward.png'
import SeeAllInvestmentTile from '../../components/Investment/SeeAllInvestmentTile';



const SeeAllInvestment = () => {
   
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
            
                <div className="col-12 col-lg-5 px-0">
                    {/* Inside Here */}
                    <div className='d-flex align-items-center px-0'>
                        <h5 className="card-title me-2" onClick={() => window.history.back()}><img src={flip} alt='flip-backward' /></h5>
                        <div className="text-black fw-normal fs-5">
                            Explore Investments
                        </div>
                    </div>
                            
                    <div className="d-flex justify-content-center mt-4">
                        <SeeAllInvestmentTile />
                    </div>
                    {/* End here */}
                </div>
            </div>
        </div>
      );
      

}

export default SeeAllInvestment