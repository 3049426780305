import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';


const PinModal = ({ closePinModal, handleSubmitPin, isSubmitting }) => {
    const [pin, setPin] = useState(['', '', '', '']);
    const inputRefs = useRef([]);

    const handleChange = (index, value) => {
        if (value.match(/^[0-9]$/) || value === '') {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);

            if (value.match(/^[0-9]$/) && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }

            if (value === '' && index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pin.every((digit) => digit !== '')) {
            handleSubmitPin(pin);
        } else {
            toast.error('Please enter a valid 4-digit PIN.', {
                autoClose: 3000,
            });
        }
    };

    return (
        <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Enter PIN</h5>
                        <button type="button" className="btn-close" onClick={closePinModal}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body d-flex justify-content-center gap-2">
                            {pin.map((digit, index) => (
                                <input
                                    key={index}
                                    type="password"
                                    maxLength={1}
                                    className="form-control"
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        width: '50px',
                                        margin: '0 5px',
                                    }}
                                />
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closePinModal} disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PinModal;


