import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const TimeoutContext = createContext();

export const TimeoutProvider = ({ children }) => {
  const navigate = useNavigate();
  const [timeoutId, setTimeoutId] = useState(null);
  const TIMEOUT_DURATION = 60 * 60 * 1000; // 60 minutes

  const resetTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      navigate("/login");
    }, TIMEOUT_DURATION);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    resetTimeout();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const handleUserAction = () => resetTimeout();

    window.addEventListener("mousemove", handleUserAction);
    window.addEventListener("keydown", handleUserAction);

    return () => {
      window.removeEventListener("mousemove", handleUserAction);
      window.removeEventListener("keydown", handleUserAction);
    };
  }, []);

  return (
    <TimeoutContext.Provider value={{ resetTimeout }}>
      {children}
    </TimeoutContext.Provider>
  );
};
