import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { fetchInvestmentPlan } from '../../Service/Investment/InvestmentService';
import InvestmentCard from './InvestmentCard';


const SeeAllInvestmentTile = () => {
    const [investmentPlans, setInvestmentPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    //fetch investment plans
    useEffect(() => {
        const fetchPlans = async () => {
            setIsLoading(true);
            try {
                const response = await fetchInvestmentPlan();
                setInvestmentPlans(response.data);
            } catch (error) {
                console.error('Failed to fetch investment plans:', error);
                setInvestmentPlans([]);

                const errorMessages = error.response?.data?.message;
                if (Array.isArray(errorMessages)) {
                    errorMessages.forEach((msg) => {
                        toast.error(msg, {
                            autoClose: 5000,
                        });
                    },
                    );
                } else {
                    toast.error(
                        error.response?.data?.message || 'Failed to fetch investment plans. Please try again.',
                        {
                            autoClose: 5000,
                        }
                    );
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchPlans();
    }, []);

    return (
        <div className="investment-list w-100">
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                    <Spinner />
                </div>
            ) : (
                investmentPlans.map((plan) => <InvestmentCard key={plan.id} plan={plan} />)
            )}
        </div>
    );
};

export default SeeAllInvestmentTile;
