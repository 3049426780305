const SubmitBtn = ({ isLoading, type, text, temp }) => {
  return (
    <div className="mb-3 mt-3">
      <button
        className="btn btn-primary continue-btn w-100"
        type={type}
        disabled={isLoading}
      >
        {isLoading ? text || "Loading..." : temp || "Continue"}
      </button>
    </div>
  );
};

export default SubmitBtn;
