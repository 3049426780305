import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const VerifyPaymentFunding = () => {
    const location = useLocation();
    const [status, setStatus] = useState('loading');
    const [message, setMessage] = useState('Verifying payment, please wait...');
    const [transactionDetails, setTransactionDetails] = useState(null);
    
    const paymentReference = new URLSearchParams(location.search).get('paymentReference');
    const token = Cookies.get('userAuth');
    const uRL = process.env.REACT_APP_URL;

    useEffect(() => {
        const verifyPayment = async () => {
            try {
                const response = await axios.post(
                    `${uRL}customer/deposit/verifypayment`, 
                    { tx_ref: paymentReference },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                const data = response.data;

                if (data.status === 'success') {
                    setStatus('success');
                    setTransactionDetails(data.details);

                    if (!data.details.paystatus) {
                        setMessage('Payment Unsuccessful');
                    } else if (data.details.paystatus === 'PAID') {
                        setMessage('Payment Successful!');
                    } else if (data.details.paystatus === 'expires') {
                        setMessage('Payment Expired. Please try again.');
                    } else if (data.details.paystatus === 'PENDING') {
                        setMessage('Payment Pending. Please wait.');
                    }
                } else {
                    setStatus('failed');
                    setMessage('Payment Failed. Please try again.');
                }
            } catch (error) {
                setStatus('failed');
                setMessage('Error verifying payment. Please try again later.');
                console.error('Verification error:', error);
            }
        };

        verifyPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentReference, token]);

    return (
        <div className="container-xxl d-flex justify-content-center align-items-center">
            <div className="text-center">
                <div className="mb-4">
                    {status === 'loading' && <div className="spinner-border text-primary" role="status"></div>}
                    {status === 'success' && <i className="bi bi-check-circle text-success display-1"></i>}
                    {status === 'failed' && <i className="bi bi-x-circle text-danger display-1"></i>}
                </div>
                
                <h4 className="fw-bold">{message}</h4>

                {status === 'success' && transactionDetails && (
                    <div className="mt-4">
                        <h5>Transaction Details</h5>
                        <div className="card">
                            <div className="card-body">
                                <p><strong>Transaction Reference:</strong> {transactionDetails.transactionreference}</p>
                                <p><strong>Amount:</strong> {transactionDetails.amount}</p>
                                <p><strong>Payment Method:</strong> {transactionDetails.payment_method ? transactionDetails.payment_method : '-'}</p>
                                <p><strong>Payment Date:</strong> {new Date(transactionDetails.payment_date).toLocaleString()}</p>
                                <p><strong>Status: </strong> 
                                    {transactionDetails.paystatus === 'PAID' && <span className="badge bg-success">Paid</span>}
                                    {transactionDetails.paystatus === 'expires' && <span className="badge bg-danger">Expired</span>}
                                    {transactionDetails.paystatus === 'PENDING' && <span className="badge bg-warning">Pending</span>}
                                    {!transactionDetails.paystatus && <span className="badge bg-secondary">Unsuccessful</span>}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {status === 'failed' && (
                    <div className="mt-4">
                        <p className="text-danger">We were unable to verify your payment. Please try again later.</p>
                    </div>
                )}

                {status !== 'loading' && (
                    <button
                        className="btn btn-primary mt-4"
                        onClick={() => window.location.href = '/'}
                    >
                        Go to Dashboard
                    </button>
                )}
            </div>
        </div>
    );
};

export default VerifyPaymentFunding;
