import { useContext } from "react";
import { Navigate } from "react-router-dom";

// Assuming you have an AuthContext or similar global context
import { AuthContext } from "./AuthContext";

const ProtectedRoute = ({ requiredStage, redirectTo, children }) => {
  const { authStage } = useContext(AuthContext);

  return authStage >= requiredStage ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
