import React from 'react'
import Img from '../../otherassets/paystack.svg'

const PaymentProvider = () => {
  return (
      <div className="d-flex flex-column my-4">
        <center>
            <p className='m-0'>Secured by</p>
            <img src={Img} alt='' width={59} />
        </center>
    </div>
  )
}

export default PaymentProvider