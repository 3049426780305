import React, { useEffect, useState } from "react";
import $ from "jquery";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "../../../src/customjs/css/page-auth.css";
import { Link } from "react-router-dom";

const Login = () => {
  const uRL = process.env.REACT_APP_URL;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  useEffect(() => {
    handleLoginManagement();
  }, []);

  const handleLoginManagement = () => {
    $("#userLogin").off("submit");

    $("#userLogin").on("submit", function (e) {
      e.preventDefault();
      var user = $(this).serialize();
      if (user) {
        $.ajax({
          type: "POST",
          url: uRL + "customer/auth/login",
          data: user,
          dataType: "json",
          beforeSend: function () {
            $(".lbtn").show();
            $(".nlbtn").hide();
          },
          success: function (response) {
            toast.success(response.message);
            Cookies.set("user", JSON.stringify(response.customer), { expires: 1 });
            Cookies.set("userAuth", response.token, { expires: 1 });
            window.location.replace("/dashboard");
            setInterval(function () {
              $(".nlbtn").show();
              $(".lbtn").hide();
            }, 1500);
          },
          error: function (xhr, status, error) {
            setInterval(function () {
              $(".nlbtn").show();
              $(".lbtn").hide();
            }, 1500);
            if (Array.isArray(xhr.responseJSON.message)) {
              $.each(xhr.responseJSON.message, function (i) {
                toast.error(xhr.responseJSON.message[i], {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              });
            } else {
              toast.error(xhr.responseJSON.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          },
        });
      }
    });
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* Register */}
          <div className="card">
            <div className="card-body">
              {/* Logo */}
              <div className="app-brand justify-content-center">
                <Link className="app-brand-link gap-2" to="/">
                  <span
                    className="app-brand-text demo text-body fw-bolder text-primary"
                    style={{ textTransform: "capitalize" }}
                  >
                    Gatober
                  </span>
                </Link>
              </div>
              {/* /Logo */}
              <h4 className="mb-2">Welcome to Gatober! 👋</h4>
              <p className="mb-4">Please sign-in to your account and start the adventure</p>
              <form id="userLogin" className="mb-3">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email or Codenumber
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="uniqueid"
                    placeholder="Enter your email or unique code"
                    autoFocus
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <Link to="/forgot-password">
                      <small>Forgot Password?</small>
                    </Link>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      className="form-control mb-0"
                      name="password"
                      placeholder="••••••••"
                      aria-describedby="password"
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      style={{ height: '50px' }}
                      onClick={togglePasswordVisibility}
                    >
                      <i
                        className={`bx ${!passwordVisible ? "bx-hide" : "bx-show"}`}
                        style={{ fontSize: "1.25rem" }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-me" />
                    <label className="form-check-label" htmlFor="remember-me">
                      Remember Me
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <button className="btn btn-primary w-100 nlbtn" type="submit">
                    Sign in
                  </button>
                  <button
                    className="btn btn-primary w-100 lbtn"
                    style={{ display: "none" }}
                    disabled
                    type="button"
                  >
                    Loading...
                  </button>
                </div>
              </form>
              <p className="text-center">
                <span>New on our platform? </span>
                <Link to="/register">
                  <span> Create an account</span>
                </Link>
              </p>
            </div>
          </div>
          {/* /Register */}
        </div>
      </div>
    </div>
  );
};

export default Login;
