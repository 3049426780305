import { TbArrowBackUp } from "react-icons/tb";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormRow from "./components/FormRow";
import SubmitBtn from "./components/SubmitBtn";
import customFetch from "../../utilities/axios";
import { AuthContext } from "./context_api/AuthContext";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { setAuthStage } = useContext(AuthContext);

  useEffect(() => {
    setAuthStage(1); // Start the stage at 1
  }, [setAuthStage]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!email) {
      toast.error("All fields are required");
      setIsLoading(false); // Ensure loading stops here too
      return;
    }
    try {
      const response = await customFetch.post(
        "customer/auth/forgotpasswordwithemail",
        {
          email,
        }
      );

      toast.success(response?.data?.message || "OTP sent to your email!");
      navigate("/forgot-password/otp", { state: { email } });
      setAuthStage(2);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Error in sending OTP");
      if (error?.response?.status === 422 || error.message) {
        toast.error(error?.message || "Bad Request");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* Register */}
          <div className="card">
            <div className="card-body">
              {/* Navigate back */}
              <button
                className="navigate-btn mb-10"
                onClick={() => navigate(-1)}
              >
                <TbArrowBackUp />
              </button>
              <div className="d-flex flex-column register-container padding mt">
                <h4 className="mb-2">Forgot Password</h4>
                <p className="mb-4">Enter your email to reset your password</p>
                <form id="userLogin" onSubmit={onSubmit} className="mb-4">
                  <FormRow
                    labelText="Email"
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                    placeholder="Type in your email address"
                    autocomplete="email" // Added here
                  />
                  <SubmitBtn type="submit" isLoading={isLoading} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
