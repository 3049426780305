import React from 'react';
import './whatsapp.css'; 

const WhatsAppButton = () => {
  const phoneNumber = '+2348137519848'; // Replace with the desired phone number

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-btn d-flex align-items-center justify-content-center"
    >
      <i className="fab fa-whatsapp" style={{ fontSize: '20px', color: 'white' }}></i>
      <span className="text-white ml-2">Message us on WhatsApp</span>
    </a>
  );
};

export default WhatsAppButton;
