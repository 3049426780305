import axios from 'axios';
import Cookies from 'js-cookie';

const uRL = process.env.REACT_APP_URL;

//fetch cable plans
export const fetchCablePlans = async (code) => {
    const token = Cookies.get('userAuth');

    try {
        // Make the API call to fetch data plans
        const response = await axios.get(`${uRL}customer/vtu/fetchcableplans/${code}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Return the response data if the call is successful
        return response.data;
    } catch (error) {
        // Log and handle the error appropriately
        console.error('Error fetching cable plans:', error);
        throw error;
    }
};


//purchase cable subscription
export const cableSubscription = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/vtu/cabletv`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; 
        } else {
            throw new Error('Failed to process cable purchase');
        }
    } catch (error) {
        console.error('Error purchasing cable subscription:', error);
        throw error; // re-throw the error for further handling
    }


};


//fetch cable codes
export const fetchCableCodes = async () => {
    const token = Cookies.get('userAuth');

    try {
        // Make the API call to fetch data plans
        const response = await axios.get(`${uRL}customer/vtu/fetchcablecodes`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Return the response data if the call is successful
        return response.data;
    } catch (error) {
        // Log and handle the error appropriately
        console.error('Error fetching cable codes:', error);
        throw error;
    }


};



//verfiy cable number
export const verfiyCableNumber = async (verfiydata) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/vtu/verfiycableno`, verfiydata, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; 
        } else {
            throw new Error('Failed to Verfiy number');
        }
    } catch (error) {
        console.error('Error verifying cab;e number:', error);
        throw error; // re-throw the error for further handling
    }


};