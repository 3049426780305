import React, { useEffect, useState } from 'react';
import FormInput from '../../components/Form/FormInput';
import flip from '../../assets/image/icons/flip-backward.png';
import placeholderImg from '../../assets/image/profile/placeholder.png';
import edit from '../../assets/image/icons/edit.png';
import { getUser } from '../../Service/GetUser/UserService';
import { updateProfile } from '../../Service/Profile/ProfileService';
import DpModal from '../../components/Profile/DpModal';
import { changeDp } from '../../Service/Profile/ProfileService';
import { toast } from 'react-toastify';

const EditProfile = () => {
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [profileDP, setProfileDp] = useState('');
    const [dob, setDob] = useState('');
    const [Submitting, setSubmitting] = useState(false);
    const [showDpModal, setShowDpModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleProceed = () => {
        setShowDpModal(true);
    };

    const fetchUserData = async () => {
        try {
            const userData = await getUser();
            setFirstName(userData.customer?.firstname || '');
            setLastName(userData.customer?.lastname || '');
            setAddress(userData.customer?.address || '');
            setProfileDp(userData.customer?.profilepicture || placeholderImg);

            // Convert DOB from "DD-MMM-YYYY" to "YYYY-MM-DD"
            const dobString = userData.customer.dob || '';
            const formattedDob = dobString ? formatDob(dobString) : '';
            setDob(formattedDob);

        } catch (error) {
            console.error("Failed to fetch user data:", error);
        }
    };

    // Helper function to format the date string
    const formatDob = (dobString) => {
        const months = {
            Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
            Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
        };

        const [day, month, year] = dobString.split('-');
        const formattedDate = `${year}-${months[month]}-${day}`;
        return formattedDate;
    };

    // Call fetchUserData initially
    useEffect(() => {
        fetchUserData();
    }, []);

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        // Format the dob back to "DD-MMM-YYYY"
        const formattedDob = formatDobForPayload(dob);

        const payload = {
            firstname,
            lastname,
            dob: formattedDob, // Use the formatted dob
            address,
        };

        try {
            const response = await updateProfile(payload);
            if (response.status === 'success') {
                toast.success(response.message, { autoClose: 3000 });
            } else {
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to Update profile');
            const errorMessages = error.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => {
                    toast.error(msg, { autoClose: 5000 });
                });
            } else {
                toast.error(error.response?.data?.message || 'Failed to update profile. Please try again.', {
                    autoClose: 5000,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    // Helper function to format the date back to "DD-MMM-YYYY"
    const formatDobForPayload = (dob) => {
        const dateObj = new Date(dob);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = dateObj.toLocaleString('default', { month: 'short' });
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const handleSubmitDP = async (profilepicture) => {
        setIsSubmitting(true); // Indicate that submission is in progress

        // Create a FormData object to handle file upload
        const formData = new FormData();
        formData.append('profilepicture', profilepicture);

        try {
            const response = await changeDp(formData);

            // Check if the response indicates success
            if (response.status === 'success') {
                toast.success(response.message, {
                    autoClose: 3000,
                });

                // Close the Dp modal
                setShowDpModal(false);
                fetchUserData();
            } else {
                // Handle unexpected response status
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to update profile image:', error);

            const errorMessages = error.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => {
                    toast.error(msg, {
                        autoClose: 5000,
                    });
                });
            } else {
                // Display a single error message or a default message
                toast.error(error.response?.data?.message || 'Failed to update profile image. Please try again.', {
                    autoClose: 5000,
                });
            }
        } finally {
            // Reset the submitting state regardless of success or failure
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className='container'>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title" onClick={() => window.history.back()}>
                                <img src={flip} alt="flip-backward" />
                            </h5>
                        </div>
                        <h4 className="text-dark mb-4">Edit Profile</h4>
                    </div>

                    <div className="row justify-content-center text-center">
                        <div className="col-6 col-md-4" style={{ maxWidth: '250px' }}>
                            <div style={{ position: 'relative' }}>
                                <img
                                    src={profileDP}
                                    alt="Profile"
                                    className="rounded-circle img-fluid mb-2"
                                    style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                                />
                                <button
                                    onClick={handleProceed}
                                    className="shadow-sm"
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0',
                                        backgroundColor: 'white',
                                        border: 'none',
                                        borderRadius: '50%',
                                        padding: '5px',
                                        height: '40px',
                                        width: '40px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img
                                        src={edit}
                                        alt="Edit"
                                        className="mx-auto"
                                        style={{ width: '16px', height: '16px' }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='container'>
                            <label className='form-label text-capitalize mb-1'>Name</label>
                            <div className='row'>
                                <div className='col-6'>
                                    <FormInput
                                        id="firstname"
                                        type="text"
                                        value={firstname}
                                        onChange={handleInputChange(setFirstName)}
                                        placeholder="First"
                                    />
                                </div>
                                <div className='col-6'>
                                    <FormInput
                                        id="lastname"
                                        type="text"
                                        value={lastname}
                                        onChange={handleInputChange(setLastName)}
                                        placeholder="Last"
                                    />
                                </div>
                            </div>

                            <FormInput
                                label="Address"
                                id="address"
                                type="text"
                                value={address}
                                onChange={handleInputChange(setAddress)}
                                placeholder="Enter address"
                            />

                            <FormInput
                                label="Date of Birth"
                                id="dob"
                                type="date"
                                value={dob}
                                onChange={handleInputChange(setDob)}
                                placeholder="Enter address"
                            />

                            <button
                                className="btn w-100 nlbtn p-3"
                                style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '16px' }}
                                type="submit"
                                disabled={Submitting}
                            >
                                {Submitting ? 'Updating...' : 'Continue'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Dp Modal */}
            {showDpModal && (
                <DpModal
                    closeDpModal={() => setShowDpModal(false)}
                    handleSubmitDP={handleSubmitDP}
                    isSubmitting={isSubmitting}
                />
            )}
        </div>
    );
};

export default EditProfile;
