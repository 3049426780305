import "./App.css";
import AppRoutes from "./AppRoutes";
import AuthRoutes from "./AuthRoutes";
import Cookies from "js-cookie";
import InstallBanner from "./components/Footer/InstallBanner";

function App() {
  const isUserLoggedIn = Cookies.get("userAuth");

  const accessRoute = isUserLoggedIn ? <AppRoutes /> : <AuthRoutes />;

  return (
    <div className="">
      {accessRoute}
      {/* <AppRoutes /> */}

      <InstallBanner />
    </div>
  );
}

export default App;
