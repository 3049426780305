import axios from 'axios';
import Cookies from 'js-cookie';

const uRL = process.env.REACT_APP_URL;

//fetch data plans
export const fetchDataPlans = async (network) => {
    const token = Cookies.get('userAuth');

    try {
        // Make the API call to fetch data plans
        const response = await axios.get(`${uRL}customer/vtu/fetchPlan/${network}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Return the response data if the call is successful
        return response.data;
    } catch (error) {
        // Log and handle the error appropriately
        console.error('Error fetching data plans:', error);
        throw error;
    }
};


//purchase data subscription
export const purchaseData = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/vtu/data`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to process data purchase');
        }
    } catch (error) {
        console.error('Error purchasing data subscription:', error);
        throw error; // re-throw the error for further handling
    }


};
