import React, { useState } from 'react';
import { toast } from 'react-toastify';

const DpModal = ({ closeDpModal, handleSubmitDP, isSubmitting }) => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [preview, setPreview] = useState(null);

    // Handle file selection and set preview
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfilePicture(file);
            setPreview(URL.createObjectURL(file)); // Generate image preview
        } else {
            setProfilePicture(null);
            setPreview(null);
        }
    };

    // Submit form with selected file
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!profilePicture) {
            toast.error("Please select an image.");
            return;
        }
        handleSubmitDP(profilePicture);
    };

    return (
        <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select Profile Image</h5>
                        <button type="button" className="btn-close" onClick={closeDpModal}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body d-flex flex-column align-items-center gap-2">
                            {preview && (
                                <div className="mb-3">
                                    <img
                                        src={preview}
                                        alt="Profile Preview"
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            )}
                            <input
                                type="file"
                                onChange={handleFileChange}
                                accept="image/*"
                                className="form-control"
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={closeDpModal}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DpModal;
