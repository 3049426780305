import React from "react";
import { Link } from "react-router-dom";

function AddToHomeInstruction() {
  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">How to Add to Home Screen</h1>

      <div className="mt-4">
        <h3 className="text-primary">For iOS Users:</h3>
        <p>Follow these simple steps to add the web app to your home screen:</p>
        <ol className="list-decimal ms-4">
          <li>Use Safari or Chrome and make sure you are on <strong>Gatober</strong> website.</li>
          <li>Tap the <strong>Share icon</strong> (a square with an upward arrow) at the top right corner of the screen.</li>
          <li>Scroll down the options and tap <strong>Add to Home Screen</strong>.</li>
          <li>You'll be prompted  to name the shortcut (you can leave it as the default name) and tap <strong>Add</strong>.</li>
          <li>The app icon will now appear on your home screen, and you can access it instantly!</li>
        </ol>
        <p className="mt-3">
          Once added, tap the icon on your home screen to launch the app at any time.
        </p>
      </div>

      <div className="mt-4 text-center">
        <Link to="/" className="btn btn-primary btn-sm">
          Go Back to Home
        </Link>
      </div>
    </div>
  );
}

export default AddToHomeInstruction;
