import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PinModal from '../../components/Pin/PinModal';
import SetPin from '../../components/Pin/SetPin'; // Import SetPin component
import { formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';
import { toast } from 'react-toastify';
import { createInvestment } from '../../Service/Investment/InvestmentService';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/Form/FormInput';
import { getUser } from '../../Service/GetUser/UserService';  // Import getUser function

import flip from '../../assets/image/icons/flip-backward.png';

const EnterInvestmentUnit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { plan } = location.state || {};

    const [isSecondSectionVisible, setIsSecondSectionVisible] = useState(false);
    const [unitNumber, setUnitNumber] = useState('1');
    const [isRead, setIsRead] = useState(false);
    const [isPinModalVisible, setIsPinModalVisible] = useState(false);
    const [isSetPinModalVisible, setIsSetPinModalVisible] = useState(false); // Track the SetPin modal visibility
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userData, setUserData] = useState(null);

    // Fetch user data to check if PIN is set
    const fetchUserData = async () => {
        try {
            const data = await getUser();
            setUserData(data.customer);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    // Handle Continue Button Click (Enter Unit Section)
    const handleContinue = (e) => {
        e.preventDefault();
        const parsedNumber = parseFloat(unitNumber);
        if (!isNaN(parsedNumber) && parsedNumber > 0) {
            setIsSecondSectionVisible(true);
        } else {
            toast.error('Please enter a valid unit number.', {
                autoClose: 3000,
            });
        }
    };

    // Handle Agree to Terms Toggle
    const handleToggle = () => {
        setIsRead(!isRead);
    };

    // Handle Continue Button in Confirm Investment Section
    const handleSecondContinue = (e) => {
        e.preventDefault();
        if (isRead) {
            if (!userData?.pin || userData.pin === 'null' || userData.pin === '') {
                // If no PIN is set, open SetPin Modal
                setIsSetPinModalVisible(true);
            } else {
                // If PIN is set, open Pin Modal
                setIsPinModalVisible(true);
            }
        } else {
            toast.error('Please agree to the terms before proceeding.', {
                autoClose: 3000,
            });
        }
    };

    // Submit the PIN
    const handleSubmitPin = async (pin) => {
        setIsSubmitting(true);
        const payload = {
            pin: pin.join(''),
            howmany: unitNumber,
            plan: plan.id.toString(),
        };

        try {
            const response = await createInvestment(payload);

            if (response.status === 'success') {
                toast.success(response.message, {
                    autoClose: 3000,
                });
                setIsPinModalVisible(false);
                navigate('/investments');
            } else {
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to purchase investment:', error);
            const errorMessage = Array.isArray(error.response?.data?.message)
                ? error.response.data.message.join(', ') // Join array messages into a single string
                : error.response?.data?.message || 'Failed to purchase investment. Please try again.';

            toast.error(errorMessage, {
                autoClose: 5000,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Close the Pin Modal
    const closePinModal = () => {
        setIsPinModalVisible(false);
    };

    // Close the Set Pin Modal
    const closeSetPinModal = () => {
        setIsSetPinModalVisible(false);
    };

    // Investment Information Mapping
    const investmentTypeMap = {
        "1": "Bike",
        "2": "Minibus",
        "3": "Jetmover",
        "4": "Truck",
    };

    const investmentType = investmentTypeMap[plan.category] || "Unknown";

    const investmentInfo = [
        { label: 'Expected returns', value: `${plan.percentage}%` },
        { label: 'Investment type', value: investmentType },
        { label: 'Returns Schedule', value: plan.duration === '1' ? 'Weekly' : 'Monthly' },
        { label: 'Maturity date', value: plan.proposedmaturitydate },
        { label: 'Periodic Return', value: formatNumberWithCommas(plan.returns) },
        { label: 'Duration', value: `${plan.no_of} ${plan.duration === '1' ? 'Weeks' : 'Months'}` },
    ];

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    {/* Back Button */}
                    <h5 className="card-title me-2" onClick={() => window.history.back()}>
                        <img src={flip} alt="flip-backward" />
                    </h5>

                    {/* Investment Unit Section */}
                    {!isSecondSectionVisible && (
                        <div id="enterUnitSection">
                            <h4 className="fs-5 fw-bold">Make Investment</h4>
                            <FormInput
                                label="No. of units"
                                type="text"
                                value={unitNumber}
                                onChange={(e) => setUnitNumber(e.target.value)}
                                placeholder=""
                            />
                            <small className="text-primary fs-c7">1 unit costs N{formatNumberWithCommas(plan.amount)}</small>
                            <button className="btn btn-primary border-0 w-100 py-2 mt-4" onClick={handleContinue}>
                                Continue
                            </button>
                        </div>
                    )}

                    {/* Confirm Investment Section */}
                    {isSecondSectionVisible && (
                        <div id="confirmInvestmentSection">
                            <h4 className="fs-5 fw-bold">Confirm Investment</h4>
                            <div className="row row-cols-2 row-cols-md-3 g-3">
                                {investmentInfo.map((detail, index) => (
                                    <div className="col" key={index}>
                                        <div className="card text-center border-0">
                                            <div className="card-body rounded p-2" style={{ backgroundColor: '#dddfe2' }}>
                                                <h6 className="card-title text-muted mb-1">{detail.label}</h6>
                                                <p className="card-text fw-bold text-dark">{detail.value}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="form-check form-switch my-4">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="toggleSwitch"
                                    checked={isRead}
                                    onChange={handleToggle}
                                />
                                <label className="form-check-label" htmlFor="toggleSwitch">
                                    I have read this and I agree to all stated.
                                </label>
                            </div>

                            <button className="btn btn-primary border-0 w-100 py-2 mt-2" onClick={handleSecondContinue}>
                                Continue
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Modal for PIN Entry */}
            {isPinModalVisible && (
                <PinModal closePinModal={closePinModal} handleSubmitPin={handleSubmitPin} isSubmitting={isSubmitting} />
            )}

            {/* Modal for Set PIN */}
            {isSetPinModalVisible && (
                <SetPin closeSetPinModal={closeSetPinModal} handleSubmitSetPin={fetchUserData} isSubmitting={isSubmitting} />
            )}
        </div>
    );
};

export default EnterInvestmentUnit;
