import React, { useState, useRef, useEffect } from 'react';
import './css/calculator.css';
import flipBackwardIcon from './images/flip-backward.png';
import myImage from './images/myIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/Form/FormInput';
import { fetchInvestmentPlan } from '../../Service/Investment/InvestmentService';
import { calculatorInvestment } from '../../Service/Calculator/CalculatorService';
import { toast } from 'react-toastify';

const Calculator = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [planId, setPlanId] = useState("Select a Plan");
    const [howmany, setHowmany] = useState(1);
    const [investmentPlans, setInvestmentPlans] = useState([]);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (value) => {
        setPlanId(value);
        setIsOpen(false);
    };

    const handleUnitChange = (e) => {
        setHowmany(e.target.value);
    };

    const handleCalculate = async () => {
        if (planId === "Select a Plan" || !howmany) {
            toast.error("Please select a plan and enter a unit.");
            return;
        }

        const payload = {
            planid: planId,
            howmany: String(howmany),
        };

        try {
            const response = await calculatorInvestment(payload);
            if (response.status === 'success') {
                toast.success(response.message, { autoClose: 3000 });
                const calculationData = response.data;
                navigate('/calcs', { state: { calculationData, howmany } });
            } else {
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to calculate:', error);
            const errorMessages = error.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => toast.error(msg, { autoClose: 5000 }));
            } else {
                toast.error(error.response?.data?.message || 'Failed to calculate. Please try again.', { autoClose: 5000 });
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await fetchInvestmentPlan();
                setInvestmentPlans(response.data);
            } catch (error) {
                console.error('Failed to fetch investment plans:', error);
                setInvestmentPlans([]);
            }
        };

        fetchPlans();
    }, []);

    return (
        <div className="page-container">
            <button className="flip-back-button">
                <div className="icon-container">
                    <img src={flipBackwardIcon} alt="go back" />
                </div>
            </button>

            <h4>Calculator</h4>

            <div className="image-container">
                <img src={myImage} alt="My Icon" />
            </div>

            <div className="calculator">
                <FormInput
                    label="Select Plan"
                    type="select"
                    options={[
                        { value: "", label: "Select a Plan", disabled: true },  // Placeholder option
                        ...investmentPlans.map(plan => ({
                            value: plan.id,
                            label: plan.name,
                        })),
                    ]}
                    value={planId}
                    onChange={(e) => handleItemClick(e.target.value)}
                    placeholder="Select a Plan"
                />


                <label htmlFor="howmany">Number of Units</label>
                <input
                    style={{
                        display: "block", marginBottom: "5vh", width: "100%",
                        borderRadius: "15px", padding: "12px", border: "1px solid lightgrey"
                    }}
                    type="number"
                    id="howmany"
                    placeholder="Enter a unit number"
                    value={howmany}
                    onChange={handleUnitChange}
                />

                <button className="calculate-button" onClick={handleCalculate}>
                    Calculate
                </button>
            </div>
        </div>
    );
};

export default Calculator;
