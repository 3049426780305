import React, { useEffect, useState } from 'react'
import InvestmentBalanceBox from '../../components/Investment/InvestmentBalanceBox';
import InvestmentCarousel from '../../components/Investment/InvestmentCarousel';
import { useNavigate } from 'react-router-dom';
import { getPayouBalance, getUserInvestments } from '../../Service/Investment/InvestmentService';
import { toast } from 'react-toastify';
import { getUser } from '../../Service/GetUser/UserService';
import InvestmentCard2 from '../../components/Investment/InvestmentCard2';
import { Spinner } from 'react-bootstrap';


const Investment = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Ongoing');
    const [payoutBal, setPayoutBal] = useState('---');
    const [investmentPlans, setInvestmentPlans] = useState([]);
    const [filteredInvestments, setFilteredInvestments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getUser();
                
                if (response.status === 'success') {
                    setPayoutBal(response.customer.returns_ngnbalance);
                    fetchUserInvestments();
                } else {
                    throw new Error(response.message || 'Unexpected response');
                }
            } catch (error) {
                console.error('Failed to get user data:', error);
                setPayoutBal('---');
                
                const errorMessages = error.response?.data?.message;
                if (Array.isArray(errorMessages)) {
                    errorMessages.forEach((msg) => {
                        toast.error(msg, {
                            autoClose: 5000,
                        });
                    });
                } else {
                    // Display a single error message or a default message
                    toast.error(
                        error.response?.data?.message || 'Failed to get user data. Please try again.',
                        {
                            autoClose: 5000,
                        }
                    );
                }
            }
        };

        const fetchUserInvestments = async () => {
            setIsLoading(true);
            try {
                const response = await getUserInvestments();
                setInvestmentPlans(response.data);
            } catch (error) {
                console.error('Failed to fetch user investments:', error);
                setInvestmentPlans([]);

                const errorMessages = error.response?.data?.message;
                if (Array.isArray(errorMessages)) {
                    errorMessages.forEach((msg) => {
                        toast.error(msg, { autoClose: 5000 });
                    });
                } else {
                    toast.error(
                        error.response?.data?.message || 'Failed to fetch investments. Please try again.',
                        { autoClose: 5000 }
                    );
                }
            } finally {
                setIsLoading(false); // End loading
            }
        };
    
        fetchUserData();
    }, []);

    useEffect(() => {
        // Filter investments based on the active tab
        let filtered = [];
        if (activeTab === 'Pending') {
            filtered = investmentPlans.filter(plan => plan.status === '0');
        } else if (activeTab === 'Ongoing') {
            filtered = investmentPlans.filter(plan => plan.status === '1');
        } else if (activeTab === 'Expired') {
            filtered = investmentPlans.filter(plan => plan.status === '2');
        }
        setFilteredInvestments(filtered);
    }, [activeTab, investmentPlans]);

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    {/* Inside Here */}
                    <div className="row d-flex justify-content-center">
                        <div className="text-center text-black fw-normal fs-5">
                        My Investments
                        </div>
                        <div className="mt-3">
                            <InvestmentBalanceBox balance={payoutBal} />
                                
                            <div className="container mt-5 px-0">
                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <span className='fw-bold'>Opportunities</span>
                                    <span className='text-cprimary fs-6 fw-bold cursor-pointer' onClick={()=>navigate('/investment/plans')}>See all</span>
                                </div>
                                <InvestmentCarousel />
                            </div>

                            {/* investment-status-section */}
                            <div className="mt-2">
                                {/* Row for the tabs */}
                                <div className="d-flex justify-content-between mb-3">
                                    <div
                                        className={`tab ${activeTab === 'Pending' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Pending')}
                                        style={{
                                            cursor: 'pointer',
                                            flex: 1,
                                            textAlign: 'center',
                                            fontWeight: activeTab === 'Pending' ? 'bold' : 'normal',
                                            borderBottom: activeTab === 'Pending' ? '3px solid #ff5500' : '2px solid lightgrey',
                                            padding: '10px 0'
                                        }}
                                    >
                                        Pending
                                    </div>
                                    <div
                                        className={`tab ${activeTab === 'Ongoing' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Ongoing')}
                                        style={{
                                            cursor: 'pointer',
                                            flex: 1,
                                            textAlign: 'center',
                                            fontWeight: activeTab === 'Ongoing' ? 'bold' : 'normal',
                                            borderBottom: activeTab === 'Ongoing' ? '3px solid #ff5500' : '2px solid lightgrey',
                                            padding: '10px 0'
                                        }}
                                    >
                                        Ongoing
                                    </div>
                                    <div
                                        className={`tab ${activeTab === 'Expired' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('Expired')}
                                        style={{
                                            cursor: 'pointer',
                                            flex: 1,
                                            textAlign: 'center',
                                            fontWeight: activeTab === 'Expired' ? 'bold' : 'normal',
                                            borderBottom: activeTab === 'Expired' ? '3px solid #ff5500' : '2px solid lightgrey',
                                            padding: '10px 0'
                                        }}
                                    >
                                        Expired
                                    </div>
                                </div>

                                {/* Content based on the active tab */}
                                <div>
                                    {isLoading ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                                            <Spinner />
                                        </div>
                                    ) : filteredInvestments.length > 0 ? (
                                        filteredInvestments.map((investment) => <InvestmentCard2 key={investment.id} investment={investment} />)
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center">
                                            You have <span className="fw-bolder px-1">No {activeTab}</span> investment
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* End here */}
                </div>
            </div>
        </div>
      );
      

}

export default Investment