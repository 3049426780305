import React, { useEffect, useState } from 'react';
import investmentImg from '../../assets/image/icons/investment.png';
import graphImg from '../../assets/image/icons/graph.png';
import balanceImg from '../../assets/image/icons/balance.png';
import calendarImg from '../../assets/image/icons/start.png'; // Add appropriate icons for the new boxes
import { getAllUserInvestmentDetails, getTotalInvestmentCount, getUserInvestments } from '../../Service/Investment/InvestmentService';
import { toast } from 'react-toastify';
import { getUser } from '../../Service/GetUser/UserService';
import { AbbreviateNumber } from '../../utilities/reusablefunctions_variables';

const ProfileStats = () => {
    const [payoutBal, setPayoutBal] = useState('---');
    const [, setIsLoading] = useState(false);
    const [investmentPlanDetails, setInvestmentPlans] = useState([]);
    const [investmentPCount, setInvestmentCount] = useState({});
    const [startDate, setStartDate] = useState('---');
    const [stopDate, setStopDate] = useState('---');
    const [lastPay, setLastPay] = useState('---');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getUser();
                if (response.status === 'success') {
                    setPayoutBal(response.customer.returns_ngnbalance);
                    setStartDate(response.customer.start_date ?? '---');
                    setStopDate(response.customer.stop_date ?? '---');
                    setLastPay(response.customer.last_pay ?? '---');

                    fetchUserInvestmentDetails();
                    fetchUInvestmentCount();
                } else {
                    throw new Error(response.message || 'Unexpected response');
                }
            } catch (error) {
                console.error('Failed to get user data:', error);
                setPayoutBal('---');
                setStartDate('---');
                setStopDate('---');
                setLastPay('---');
                toast.error(
                    error.response?.data?.message || 'Failed to get user data. Please try again.',
                    { autoClose: 5000 }
                );
            }
        };

        const fetchUserInvestmentDetails = async () => {
            setIsLoading(true);
            try {
                const response = await getAllUserInvestmentDetails();
                setInvestmentPlans(response);
            } catch (error) {
                console.error('Failed to fetch user investments:', error);
                toast.error(
                    error.response?.data?.message || 'Failed to fetch investments. Please try again.',
                    { autoClose: 5000 }
                );
            } finally {
                setIsLoading(false);
            }
        };

        const fetchUInvestmentCount = async () => {
            setIsLoading(true);
            try {
                const response = await getTotalInvestmentCount();
                setInvestmentCount(response);
            } catch (error) {
                console.error('Failed to fetch user investments count:', error);
                toast.error(
                    error.response?.data?.message || 'Failed to fetch investments. Please try again.',
                    { autoClose: 5000 }
                );
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    return (
        <div className="mt-4 p-2" style={{ backgroundColor: '#F8F8F8' }}>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="card shadow-sm" style={{ borderRadius: '15px', flex: '1', margin: '0 10px' }}>
                    <div className="card-body">
                        <div className="d-flex justify-content-center">
                            <img className="d-none d-md-block" src={investmentImg} alt="Total Invested" style={{ marginBottom: '10px' }} />
                        </div>
                        <h6 className="text-muted text-center" style={{ fontSize: '0.8rem', marginBottom: '4px' }}>
                            Total Invested
                        </h6>
                        <p className="card-text fw-bold fs-6 mt-0 text-center">
                            ₦{AbbreviateNumber(investmentPlanDetails.investedamount ?? '0')}
                        </p>
                    </div>
                </div>

                <div className="card shadow-sm" style={{ borderRadius: '15px', flex: '1', margin: '0 10px' }}>
                    <div className="card-body">
                        <div className="d-flex justify-content-center">
                            <img className="d-none d-md-block" src={graphImg} alt="Net Performance" style={{ marginBottom: '10px' }} />
                        </div>
                        <h6 className="text-muted text-center" style={{ fontSize: '0.8rem', marginBottom: '4px' }}>
                            Number of Investments
                        </h6>
                        <p className="card-text text-success fw-bold fs-6 mt-0 text-center">
                            {investmentPCount.total}
                        </p>
                    </div>
                </div>

                <div className="card shadow-sm" style={{ borderRadius: '15px', flex: '1', margin: '0 10px' }}>
                    <div className="card-body">
                        <div className="d-flex justify-content-center">
                            <img className="d-none d-md-block" src={balanceImg} alt="Portfolio Balance" style={{ marginBottom: '10px' }} />
                        </div>
                        <h6 className="text-muted text-center" style={{ fontSize: '0.8rem', marginBottom: '4px' }}>
                            Payout Balance
                        </h6>
                        <p className="card-text fw-bold fs-6 mt-0 text-center">₦{AbbreviateNumber(payoutBal)}</p>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default ProfileStats;
