import React, { useEffect, useState } from 'react'
import './Transaction.css'
import { Link } from 'react-router-dom';
import TransactionCard from './TransactionCard';

const TransactionList = ({list}) => {
    const isEmpty = (obj) => Object.entries(obj).length === 0;

    return (
        <>
            <div className="card mt-4">
                <div className="row row-bordered g-0">
                <div className="col-md-12">
                    <div className="card-header d-flex align-items-center justify-content-between py-3 px-2">
                        <h5 className="card-title m-0 me-2">History</h5>
                        <Link to="/alltransactions">See more</Link>
                    </div>
                    <div className="card-body py-0 px-2">
                        {
                            isEmpty(list) ?
                            <span>No transaction yet.</span>
                            :
                            Object.entries(list).map(([monthYear, transactions]) => (
                                <div key={monthYear}>
                                    <h6 className="t-tag">{monthYear}</h6> {/* Display the Month Year as the header */}
                                    <ul className="p-0 m-0">
                                        {transactions.map((transaction) => (
                                            // <div key={transaction.id} style={{ marginBottom: '10px' }}>
                                            // <h4>{transaction.title}</h4>
                                            // <p>Amount: {transaction.amount}</p>
                                            // <p>Date: {transaction.date} - Time: {transaction.time}</p>
                                            // <p>Customer: {transaction.customer.name}</p>
                                            // </div>
                                            // <TransactionCard ttype="1" icon="fa-plus-circle" iconColor="t-icon-holder1" title="Deposit" description="Received from Chuks Samuel" amount="+N20000" date="24 Nov 2024, 19:26" />
                                            <TransactionCard
                                                key={transaction.id}
                                                tid={transaction.id}
                                                ttype={transaction.type} 
                                                twhich={transaction.which} 
                                                tclasstype={transaction.classtype} 
                                                description={transaction.title} 
                                                amount={transaction.amount} 
                                                date={transaction.date+', '+transaction.time} 
                                                details={transaction.details}
                                                tstatus={transaction.status}

                                            />
                                        ))}
                                    </ul>                            
                                </div>
                            ))
                        }
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

export default TransactionList;
