import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/calculatorSummary.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';

ChartJS.register(ArcElement, Tooltip, Legend);

const CalculatorSummary = () => {
    const Navigate = useNavigate();
    const location = useLocation();
    const { calculationData, howmany } = location.state || {}; // Get the data from state
    // console.log("Calculation Data:", calculationData);

    const data = {
        labels: ["Category 1", "Category 2", "Category 3", "Category 4", "Category 5"],
        datasets: [
            {
                data: [14, 19, 24, 30, 13],
                backgroundColor: ["green", "#8B0000", "orange", "blue", "yellow"],
                hoverBackgroundColor: ["green", "#8B0000", "orange", "blue", "yellow"],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        cutout: "60%",
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        },
    };

    return (
        <div className='calculator-summary'>
            <button onClick={() => window.history.back()}>
                <FontAwesomeIcon icon={faChevronLeft} className="flip-back-icon" />
            </button>

            <h4>Calculator Summary</h4>

            <div style={{ position: "relative", width: "250px", margin: "10vh auto 5vh" }}>
                <Doughnut data={data} options={options} />
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                }}>
                    <span style={{ fontSize: "25px", fontWeight: "bold", color: "black" }}>
                        {calculationData ? `${calculationData.percentage}%` : "..."}
                    </span>
                    <br />
                    <span style={{ fontSize: "14px", color: "#777" }}>Returns</span>
                </div>
            </div>

            <div className="investment-details">
                <div className="detail-row">
                    <p><strong>Duration:</strong></p>
                    <p>{calculationData ? calculationData.duration : "Loading..."}</p>
                </div>
                <div className="detail-row">
                    <p><strong>Weekly Return:</strong></p>
                    <p>{calculationData ? `${formatNumberWithCommas(calculationData.bit_return)}` : "Loading..."}</p>
                </div>
                <div className="detail-row">
                    <p><strong>Total Amount Returned:</strong></p>
                    <p>{calculationData ? `₦${formatNumberWithCommas(calculationData.amountreturnable)}` : "Loading..."}</p>

                </div>
                <div className="detail-row">
                    <p><strong>Percentage Profit:</strong></p>
                    <p>{calculationData ? `${calculationData.percentage}%` : "Loading..."}</p>
                </div>
                <div className="detail-row">
                    <p><strong>Amount to be paid:</strong></p>
                    <p>{calculationData ? `₦${formatNumberWithCommas(calculationData.amounttobepaid)}` : "Loading..."}</p>
                </div>
                <div className="detail-row">
                    <p><strong>Number of Unit:</strong></p>
                    <p>{howmany || "Loading..."}</p>
                </div>
            </div>

            <button className="investment-button">
                Explore All Investment Plans
            </button>
        </div>
    );
};

export default CalculatorSummary;
