import React, { useEffect, useState } from 'react';
import flip from '../../assets/image/icons/flip-backward.png';
import NotificationCard from '../../components/Notification/NotificationCard';
import { fetchNotification, markAllAsRead } from '../../Service/Notification/NotificationService';
import { Spinner } from 'react-bootstrap'; // Import Spinner for the loader

const NotificationPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false); // Loader state

    const loadNotifications = async (page = 1) => {
        try {
            const data = await fetchNotification(page);
            setNotifications(data.data);
            setCurrentPage(data.current_page);
            setTotalPages(data.last_page);
        } catch (error) {
            console.error("Error loading notifications", error);
        }
    };

    // Mark all notifications as read
    const handleMarkAllAsRead = async () => {
        setLoading(true); // Start loader
        try {
            await markAllAsRead(); // Call the service to mark all notifications as read
            loadNotifications(); // Reload notifications to reflect the changes
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        } finally {
            setLoading(false); // Stop loader when finished
        }
    };

    useEffect(() => {
        loadNotifications();
    }, []);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            loadNotifications(newPage);
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className='container'>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <h5 className="card-title cursor-pointer" onClick={() => window.history.back()}>
                                <img src={flip} alt="flip-backward" />
                            </h5>
                        </div>
                        <div className="dropdown-header d-flex justify-content-between py-1">
                            <h5 className="text-body mb-0 me-auto">Notifications</h5>
                            <h5
                                className="text-body fs-6 cursor-pointer"
                                onClick={handleMarkAllAsRead} // Trigger mark all as read
                            >
                                {loading ? (
                                    <Spinner animation="border" size="sm" /> // Show loader while marking as read
                                ) : (
                                    'Mark all as read'
                                )}
                            </h5>
                        </div>
                    </div>

                    {/* Show "No Notifications" Message if List is Empty */}
                    {notifications.length === 0 ? (
                        <div className="text-center mt-4">
                            <p className="text-muted">No notifications</p>
                        </div>
                    ) : (
                        <NotificationCard notifications={notifications} />
                    )}

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-between mt-4">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="btn btn-outline-primary"
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="btn btn-outline-primary"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;
