import React, { useEffect, useState } from 'react';
import flip from '../../assets/image/icons/flip-backward.png';
import { getUser } from '../../Service/GetUser/UserService';
import { Spinner } from 'react-bootstrap';
import ChangePin from '../../components/Pin/ChangePin';
import { changePin, setPin } from '../../Service/Profile/ProfileService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ChangePasswordModal from '../../components/Pin/ChangePasswordModal';
import { ChangePassword } from '../../Service/Profile/ProfileService';
import SetPin from '../../components/Pin/SetPin';


const SecurityPage = () => {
    const [userData, setUserData] = useState(null);
    const [showChangePinModal, setShowChangePinModal] = useState(false);
    const [showSetPinModal, setShowSetPinModal] = useState(false);

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleProceed = () => {
        setShowChangePinModal(true);
    };

    const handleSetPinProceed = () => {
        setShowSetPinModal(true);
    };

    const openPasswordModal = () => {
        setShowChangePasswordModal(true);
    }

    const fetchUserData = async () => {
        try {
            const data = await getUser();
            setUserData(data.customer);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // Call fetchUserData initially
    useEffect(() => {
        fetchUserData();
    }, []);

    if (!userData) {
        return <>
            <div className='text-primary' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <Spinner />
            </div>
        </>;
    }

    const handleSubmitSetPin = async (newpin, confirmPin) => {
        console.log(confirmPin);

        setIsSubmitting(true);
        if (newpin.join('') !== confirmPin.join('')) {
            toast.error('New PIN and Confirm PIN do not match.', { autoClose: 5000 });
            setIsSubmitting(false);
            return;
        }
        const payload = {
            pin: newpin.join(''),
            pin_confirmation: confirmPin.join(''),
        };

        try {
            const response = await setPin(payload);
            if (response.status === 'success') {
                console.log(response);
                toast.success(response.message, { autoClose: 3000 });
                setShowSetPinModal(false);
                fetchUserData(); // Refresh user data after setting the PIN
            } else {
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            handleError(error, 'Failed to set PIN.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmitChangePin = async (newpin, oldpin) => {
        setIsSubmitting(true);

        // Create the payload
        const payload = {
            oldpin: oldpin.join(''),// Combine the oldpin array into a single string
            newpin: newpin.join(''),// Combine the newpin array into a single string
        };

        try {
            const response = await changePin(payload);

            // Check if the response indicates success
            if (response.status === 'success') {
                toast.success(response.message, {
                    autoClose: 3000,
                });

                // Close the change PIN modal
                setShowChangePinModal(false);
            } else {
                // Handle unexpected response status
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to change pin:', error);

            const errorMessages = error.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => {
                    toast.error(msg, {
                        autoClose: 5000,
                    });
                });
            } else {
                // Display a single error message or a default message
                toast.error(error.response?.data?.message || 'Failed to change pin. Please try again.', {
                    autoClose: 5000,
                });
            }
        } finally {
            // Reset the submitting state regardless of success or failure
            setIsSubmitting(false);
        }

    };

    const handleSubmitChangePassword = async (oldpassword, password) => {
        setIsSubmitting(true);

        // Create the payload
        const payload = {
            oldpassword,
            password,
        };

        try {
            const response = await ChangePassword(payload);

            // Check if the response indicates success
            if (response.status === 'success') {
                toast.success(response.message, {
                    autoClose: 3000,
                });

                // Close the change password modal
                setShowChangePasswordModal(false);
            } else {
                // Handle unexpected response status
                throw new Error(response.message || 'Unexpected response');
            }
        } catch (error) {
            console.error('Failed to change password:', error);

            const errorMessages = error.response?.data?.message;
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => {
                    toast.error(msg, {
                        autoClose: 5000,
                    });
                });
            } else {
                // Display a single error message or a default message
                toast.error(error.response?.data?.message || 'Failed to change password. Please try again.', {
                    autoClose: 5000,
                });
            }
        } finally {
            // Reset the submitting state regardless of success or failure
            setIsSubmitting(false);
        }
    };
    const handleError = (error, defaultMessage) => {
        console.error(error);
        const errorMessages = error.response?.data?.message;
        if (Array.isArray(errorMessages)) {
            errorMessages.forEach((msg) => {
                toast.error(msg, { autoClose: 5000 });
            });
        } else {
            toast.error(error.response?.data?.message || defaultMessage, {
                autoClose: 5000,
            });
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className='container'>
                        {/* Back Button and Header */}
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title" onClick={() => window.history.back()}>
                                <img src={flip} alt="flip-backward" style={{ cursor: 'pointer' }} />
                            </h5>
                        </div>
                        <h4 className="text-dark mb-4">Security</h4>

                        {/* Security Page Content */}
                        <div className="bg-white p-4 shadow-sm rounded">
                            {/* Sign-In Email */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Sign-In Email</span>
                                <span className="text-dark">{userData.email}</span>
                            </div>
                            <hr />

                            {/* Password */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Password</span>
                                <span className="text-primary" style={{ cursor: 'pointer' }} onClick={openPasswordModal}>Change password</span>
                            </div>
                            <hr />

                            {/* PIN */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">PIN</span>
                                <span className="text-primary" style={{ cursor: 'pointer' }} onClick={handleProceed}>Change PIN</span>
                            </div>
                            <hr />
                            {/* Conditionally render the Set PIN tile */}
                            {!userData.pin || userData.pin === 'null' || userData.pin === '' ? (
                                <>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-muted">Set PIN</span>
                                        <span
                                            className="text-primary"
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleSetPinProceed}
                                        >
                                            Set PIN
                                        </span>
                                    </div>
                                </>
                            ) : null}


                            {/* Phone Number */}
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-muted">Phone number</span>
                                <span className="text-dark">{userData.phonenumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showChangePinModal && (
                <ChangePin
                    closeChangePinModal={() => setShowChangePinModal(false)}
                    handleSubmitChangePin={handleSubmitChangePin}
                    isSubmitting={isSubmitting}
                />
            )}
            {showSetPinModal && (
                <SetPin
                    closeSetPinModal={() => setShowSetPinModal(false)}
                    handleSubmitSetPin={handleSubmitSetPin}
                    isSubmitting={isSubmitting}
                />
            )}

            {showChangePasswordModal && (
                <ChangePasswordModal
                    closeChangePasswordModal={() => setShowChangePasswordModal(false)}
                    handleSubmitChangePassword={handleSubmitChangePassword}
                    isSubmitting={isSubmitting}
                />
            )}
        </div>
    );
};

export default SecurityPage;
