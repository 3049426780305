import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import styles from './Header.css';
const Header2 = ({ menuOpen, toggleMenu }) => {
  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const [loading, setLoading] = useState(true);
  const closeMenu = () => {
    if (menuOpen) {
      toggleMenu();
    }
  };
  useEffect(() => {


  }, []);

  return (

    <aside id="layout-menu" className={"layout-menu menu menu-vertical bg-menu-theme layout-menu-custom " + (menuOpen ? "open-menu" : "close-menu")} style={{ touchAction: 'none', userSelect: 'none' }}>
      <div className="container-xxl d-flex h-100 flex-column p-0">
        <a href="#" className="menu-horizontal-prev disabled d-none"></a>
        <div className="navbar-brand app-brand demo d-xl-flex py-0 me-4">
          <Link to="./" className="app-brand-link gap-2">
            <span className="app-brand-text demo menu-text fw-bolder text-primary">Gatober</span>
            {/* <img src="https://localhost/solvent/logo.png" className="img-responsive allo"/> */}
          </Link>
          <div className="layout-menu-toggle menu-link text-large ms-auto" style={{ cursor: 'pointer', display: menuOpen ? 'block' : 'none' }} onClick={toggleMenu}>
            <i className="bx bx-chevron-left bx-sm align-middle"></i>
          </div>
        </div>
        <ul className="menu-inner overflow-auto" style={{ marginLeft: '0px' }}>
          {/* <!-- Dashboards --> */}
          <li className="menu-item
                active">
            <Link to="/" className="menu-link" onClick={closeMenu}>
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div data-i18n="Dashboard">Dashboard</div>
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/investments" className="menu-link" onClick={closeMenu}>
              <i className="menu-icon tf-icons bx bx-layout"></i>
              <div data-i18n="Investment">Investment</div>
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/utilitybills" className="menu-link" onClick={closeMenu}>
              <i className="menu-icon tf-icons bx bx-collection"></i>
              <div data-i18n="Utility Bills">Utility Bills</div>
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/account/profile" className="menu-link" onClick={closeMenu}>
              <i className="menu-icon tf-icons bx bx-user"></i>
              <div data-i18n="Account">Account</div>
            </Link>
          </li>
          <li className="menu-item" onClick={closeMenu}>
            <Link className="menu-link" data-bs-toggle="modal" data-bs-target=".logout_modal">
              <i className="menu-icon tf-icons bx bx-log-out"></i>
              <div data-i18n="Logout">Logout</div>
            </Link>
          </li>
        </ul>
        <div className="menu-horizontal-wrapper"></div>
        <a href="#" className="menu-horizontal-next d-none"></a>
      </div>
    </aside>
  )
}

export default Header2