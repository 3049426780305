import axios from 'axios';
import Cookies from 'js-cookie';


const uRL = process.env.REACT_APP_URL;

export const getUser = async () => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.get(`${uRL}customer`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to get user object');
        }
    } catch (error) {
        console.error('Error fetching user object', error);
        throw error; // re-throw the error for further handling
    }
};


