import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import placeholderImg from '../../assets/image/profile/placeholder.png';

import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { getUser } from '../../Service/GetUser/UserService';
import { fetchNotification, markAllAsRead, markRead } from '../../Service/Notification/NotificationService';
import { Button, Modal, Spinner } from 'react-bootstrap';

const Navbar = ({ menuOpen, toggleMenu }) => {


  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const [userData, setUserData] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false); // Loader state


  const [selectedNotification, setSelectedNotification] = useState(null);


  useEffect(() => {
    loadNotifications();
  }, []);

  function clearCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  const logout = async () => {
    const logoutURL = uRL + "customer/logout";

    try {
      toast.warning("Logging you out...");

      const response = await fetch(logoutURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();




        localStorage.clear();
        clearCookies();
        setTimeout(() => {
          window.location.replace("/login");
        }, 2000);
      } else {
        const errorData = await response.json();
        if (errorData && errorData.message) {
          toast.error(
            Array.isArray(errorData.message) ? errorData.message[0] : errorData.message
          );
        } else {
          toast.error("Failed to log out. Please try again.");
        }
        localStorage.clear();
        clearCookies();
        setTimeout(() => {
          window.location.replace("/login");
        }, 2000);
      }
    } catch (error) {
      console.error("Error during logout:", error);
      toast.error("An unexpected error occurred. Redirecting to login.");
      localStorage.clear();
      clearCookies();
      setTimeout(() => {
        window.location.replace("/login");
      }, 2000);
    }
  };


  const fetchUserData = async () => {
    try {
      const data = await getUser();
      setUserData(data.customer);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const loadNotifications = async () => {
    try {
      const data = await fetchNotification(1, '0'); // Fetch the first page of notifications
      setNotifications(data.data.slice(0, 5)); // Limit to first 8 notifications
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleRemoveNotification = (index) => {
    setNotifications((prevNotifications) => prevNotifications.filter((_, i) => i !== index));
  };


  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    if (notification.status === '0') {
      markNotificationAsRead(notification.id);
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notification.id ? { ...n, status: '1' } : n
        )
      );
    }
  };

  const handleMarkAllAsRead = async () => {
    setLoading(true); // Start loader
    try {
      await markAllAsRead(); // Call the service to mark all notifications as read
      loadNotifications(); // Reload notifications to reflect the changes
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    } finally {
      setLoading(false); // Stop loader when finished
    }
  };
  const markNotificationAsRead = async (notificationId) => {
    try {
      const data = { notificationid: notificationId.toString() };
      const response = await markRead(data);
      if (response.status === 'success') {
        setNotificationList((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === notificationId
              ? { ...notification, status: '1' } // Assuming '1' means read
              : notification
          )
        );
        loadNotifications();
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };
  const handleCloseModal = () => {
    setSelectedNotification(null);
  };
  const closeMenu = () => {
    if (menuOpen) {
      toggleMenu();
    }
  };
  const getInitials = (name) => {
    return name.split(" ").map(word => word[0]).join("").slice(0, 2).toUpperCase();
  };


  // Call fetchUserData initially
  useEffect(() => {
    fetchUserData();
  }, []);




  return (
    <>
      <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
        <div className="container-xxl">
          <div className="navbar-brand app-brand demo d-none d-xl-flex d-lg-flex py-0 me-4">
            <Link to="./" className="app-brand-link gap-2">

              <span className="app-brand-text demo menu-text fw-bolder text-primary">Gatober</span>
            </Link>
            <Link className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
              <i className="bx bx-chevron-left bx-sm align-middle"></i>
            </Link>
          </div>

          <div
            className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none d-lg-none d-block"
            style={{ cursor: 'pointer', display: menuOpen ? 'none' : 'block' }}
          >
            <div className="nav-item nav-link px-0 me-xl-4" onClick={toggleMenu}>
              <i className="bx bx-menu bx-sm"></i>
            </div>
          </div>

          <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse" onClick={closeMenu}>
            <ul className="navbar-nav flex-row align-items-center ms-auto">

              {/* <!-- Style Switcher --> */}
              <li className="nav-item me-2 me-xl-0">
                <Link className="nav-link style-switcher-toggle hide-arrow">
                  <i className='bx bx-sm'></i>
                </Link>
              </li>
              {/* <!--/ Style Switcher -->
                <!-- Quick links  --> */}
              {/* <!-- Calculator --> */}
              <li className="nav-item me-3 me-xl-1">
                <Link to="/calulator" className="nav-link">
                  <i className="bx bx-calculator bx-sm"></i>
                </Link>
              </li>
              {/* <!--/ Calculator -->

                {/* <!-- Notification --> */}
              <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                <Link
                  className="nav-link dropdown-toggle hide-arrow"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Bell Icon */}
                  <i className="bx bx-bell bx-sm"></i>
                  {/* Notification Badge */}
                  {notifications.length > 0 && (
                    <span className="badge bg-danger rounded-pill badge-notifications">
                      {notifications.length}
                    </span>
                  )}
                </Link>
                <ul className="dropdown-menu dropdown-menu-end py-0">
                  <li className="dropdown-menu-header border-bottom">
                    <div className="dropdown-header d-flex justify-content-between py-1">
                      <h5 className="text-body mb-0 me-auto">Notifications</h5>
                      <h5
                        className="text-body fs-6 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation(); // Trigger mark all
                          handleMarkAllAsRead();
                        }}
                      >
                        {loading ? (
                          <Spinner animation="border" size="sm" /> // Show loader while marking as read
                        ) : (
                          'Mark all as read'
                        )}
                      </h5>
                    </div>
                  </li>

                  {/* Notification List or Empty State */}
                  <li className="dropdown-notifications-list scrollable-container">
                    {notifications.length > 0 ? (
                      <ul className="list-group list-group-flush">
                        {notifications.map((notification, index) => (
                          <li
                            key={index}
                            className="list-group-item list-group-item-action dropdown-notifications-item"
                            onClick={() => handleNotificationClick(notification)}
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar">
                                  <span className="avatar-initial rounded-circle bg-label-danger">
                                    {getInitials(notification.title)}
                                  </span>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h6 className="mb-1">{notification.title}</h6>
                                <p className="mb-0">{notification.description}</p>
                                <small className="text-muted">
                                  {new Date(notification.created_at).toLocaleString()}
                                </small>
                              </div>
                              <div className="flex-shrink-0 dropdown-notifications-actions">
                                <Link className="dropdown-notifications-read">
                                  <span className="badge badge-dot"></span>
                                </Link>
                                <Link
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the dropdown from closing
                                    handleRemoveNotification(index);
                                  }}
                                  className="dropdown-notifications-archive"
                                >
                                  <span className="bx bx-x"></span>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center p-3">
                        <p className="mb-0 text-muted">No new notifications</p>
                      </div>
                    )}
                  </li>

                  {/* Footer */}
                  <li
                    className="dropdown-menu-footer border-top"
                    style={{ paddingTop: '10px', marginTop: '5px', borderTop: '1px solid #e0e0e0' }}
                  >
                    <a href="/notification" className="dropdown-item d-flex justify-content-center p-2">
                      View all notifications
                    </a>
                  </li>
                </ul>
              </li>


              {/* <!--/ Notification -->

                <!-- User --> */}
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <Link className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <div className="avatar avatar-online">
                    <img src={userData.profilepicture || placeholderImg} alt="" className="w-px-40 h-auto rounded-circle" />
                  </div>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a className="dropdown-item" href="pages-account-settings-account.html">
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar avatar-online">
                            <img src={userData.profilepicture || placeholderImg} alt="" className="w-px-40 h-auto rounded-circle" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <span className="fw-400 fs-9 d-block">{`${userData.firstname} ${userData.lastname}`}</span>
                          <small className="text-muted">{userData.email}</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>

                    <Link to="/account/profile" className="dropdown-item">
                      <i className="bx bx-user me-2"></i>
                      <span className="align-middle">My Profile</span>
                    </Link>
                  </li>
                  <li>

                    <Link to="/account/security" className="dropdown-item">
                      <i className="bx bx-cog me-2"></i>
                      <span className="align-middle">Settings</span>
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>

                    <Link to="/account/customer-support" className="dropdown-item">
                      <i className="bx bx-support me-2"></i>
                      <span className="align-middle">Help</span>
                    </Link>

                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target=".logout_modal">
                      <i className="bx bx-power-off me-2"></i>
                      <span className="align-middle">Log Out</span>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <!--/ User --> */}
            </ul>
          </div>
          {/* <!-- Search Small Screens --> */}
          <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
            <input type="text" className="form-control search-input  border-0" placeholder="Search..." aria-label="Search..." />
            <i className="bx bx-x bx-sm search-toggler cursor-pointer"></i>
          </div>
        </div>
      </nav>


      {/* Stare Logout Modal */}
      <div className="modal custom-modal fade mymodal logout_modal" id="logout_modal" role="dialog" style={{ zIndex: '10000' }}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header text-center">
                <h3>Logout</h3>
                <p>Are you sure you want to logout?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <span data-bs-dismiss="modal" className="btn btn-primary logout-continue-btn" onClick={logout}>Logout</span>
                    <span data-bs-dismiss="modal" className="btn btn-secondary logout-cancel-btn">Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Approve Modal */}


      {/* Notification Modal */}
      <Modal show={selectedNotification !== null} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedNotification?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedNotification?.description}</p>
          <small>{new Date(selectedNotification?.created_at).toLocaleString()}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Navbar