import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './WalletCard.css';

const WalletCard = ({ balance }) => {
    const [balanceVisible, setBalanceVisible] = useState(true);
    const navigate = useNavigate();

    const toggleBalanceVisibility = () => {
        setBalanceVisible((prevVisible) => !prevVisible);
    };

    const navigateToComingSoon = () => {
        navigate('/comingsoon');
    };

    return (
        <div className="card shadow-sm mb-4">
            <div className="card-body walletspace">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="card-title">Wallet Balance</h5>
                    <i className="fas fa-wallet text-primary" style={{ fontSize: '1.5rem' }}></i>
                </div>
                <div className="d-flex justify-content-between">
                    <h2 className="card-text mb-4">
                        {balanceVisible ? <span>{'₦' + balance}</span> : <span>****</span>} &nbsp; &nbsp;
                    </h2>
                    <span className="cursor-pointer">
                        <i
                            className={`bx ${!balanceVisible ? 'bx-hide' : 'bx-show'} toggle-password`}
                            onClick={toggleBalanceVisibility}
                            style={{ fontSize: '24px' }}
                        ></i>
                    </span>
                </div>

                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={navigateToComingSoon}>
                        Deposit
                    </button>
                    <button className="btn btn-outline-primary" onClick={navigateToComingSoon}>
                        Withdraw
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WalletCard;
