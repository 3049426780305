import React from 'react'

const TransactionList = () => {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    {/* ///////// i write here//////////// */}
                    Custom Page
                </div>
            </div>
        </div>
    )

}

export default TransactionList