import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import flip from '../../assets/image/icons/flip-backward.png'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';

const InvestmenDetails = () => {
    const [walletBalance, setWalletBalance] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const { plan } = location.state || {};

    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    useEffect(() => {
        handleDashboardManagement();
    }, []);

    const handleDashboardManagement = () => {
        $.ajax({
            type: 'GET',
            url: `${uRL}customer`,
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            },
            success: function (res) {
                setWalletBalance(res.customer.ngnbalance);
            },
            error: function (xhr, textStatus) {
                console.error('Error:', textStatus);
            }
        });
    };

    // Mapping investment types to their labels
    const investmentTypeMap = {
        "1": "Bike",
        "2": "Minibus",
        "3": "Jetmover",
        "4": "Truck",
    };
    const investmentTypeImgMap = {
        "1": `https://gaviceglobal.com/gimg/plan-icons/bike.png`,
        "2": `https://gaviceglobal.com/gimg/plan-icons/minibus.png`,
        "3": `https://gaviceglobal.com/gimg/plan-icons/jetmover.png`,
        "4": `https://gaviceglobal.com/gimg/plan-icons/truck.png`,
    };


    // Determine investment type based on the plan's investment type value
    const investmentType = investmentTypeMap[plan.category] || "Unknown";
    const investmentImgType = investmentTypeImgMap[plan.category] || "Unknown";

    const investmentInfo = [
        { label: 'Expected returns', value: `${plan.percentage}%` },
        { label: 'Investment type', value: investmentType },
        { label: 'Returns Schedule', value: plan.duration === '1' ? 'Weekly' : 'Month' },
        { label: 'Maturity date', value: plan.proposedmaturitydate },
        { label: 'Periodic Return', value: formatNumberWithCommas(plan.returns) },
        { label: 'Duration', value: `${plan.no_of} ${plan.duration === '1' ? 'Weeks' : 'Months'}` }
    ];

    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <h5 className="card-title me-2 pb-1" onClick={() => window.history.back()}><img src={flip} alt='flip-backward' /></h5>

                    {plan && (
                        <>
                            <div className="card shadow-sm mb-2">
                                <div className="card-body walletspace p-0">
                                    <div className="d-flex align-items-center justify-content-center h-px-150" style={{ overflow: 'hidden' }}>
                                        <img
                                            src={investmentImgType}
                                            alt={investmentType}
                                            style={{
                                                maxWidth: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="d-flex justify-content-end">
                            <p className='text-blue-dim1 fw-500'>
                                    Verified Opportunities
                                    <img src={VerifiedIcon} className='ps-1' alt="Verified" />
                                </p>
                            </div> */}

                            <div className="d-flex justify-content-between gap-1 pt-2">
                                <h3 className="w-50 text-truncate">{plan.name}</h3>
                                <div className="w-50 text-end">

                                    <h4 className='text-success mb-0'>₦{formatNumberWithCommas(plan.amount)}</h4>
                                    <small className='text-grey fw-500'>per unit</small>
                                </div>
                            </div>

                            <div>Balance <span className="badge text-bg-success rounded-pill mt-0">₦{formatNumberWithCommas(walletBalance)}</span></div>
                            <button type="button" className="btn mt-2 text-white py-1" style={{ backgroundColor: '#4848e7' }} onClick={() => navigate('/comingsoon')}>
                                Top up
                            </button>

                            <hr />

                            <p className="text-center text-grey fw-500">There are still available units to buy</p>

                            {/* Investment Information */}
                            <div className="row row-cols-2 row-cols-md-3 g-3">
                                {investmentInfo.map((detail, index) => (
                                    <div className="col" key={index}>
                                        <div className="card text-center border-0 ">
                                            <div className="card-body rounded p-2" style={{ backgroundColor: '#dddfe2' }}>
                                                <h6 className="card-title text-muted mb-1">{detail.label}</h6>
                                                <p className="card-text fw-bold text-dark">{detail.value}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <button className="btn w-100 nlbtn p-3 mt-5" style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '16px' }} onClick={() => navigate('/investment/unit', { state: { plan } })}>
                                Continue
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InvestmenDetails;
