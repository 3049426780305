import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const Header = () => {
  const location = useLocation(); // React Router hook to access the current URL
  const token = Cookies.get('userAuth');

  // Function to determine if a tab should have the 'active' class
  const isActive = (path) => location.pathname === path;

  const isInvestmentActive = () => {
    const investmentPaths = [
      '/investments', '/seeallinvestment'
    ];
    return (
      investmentPaths.includes(location.pathname) ||
      location.pathname.startsWith('/investmentdetails/') ||
      location.pathname.startsWith('/investmentunit/')
    );
  };

  return (
    <aside id="layout-menu" className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0">
      <div className="container-xxl d-flex h-100">
        <ul className="menu-inner" style={{ justifyContent: 'center', alignItems: 'center' }}>
          {/* Dashboard */}
          <li className={`menu-item ${isActive('/') ? 'active' : ''}`}>
            <Link to="/" className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div data-i18n="Dashboard">Dashboard</div>
            </Link>
          </li>

          {/* Investments */}
          <li className={`menu-item ${isInvestmentActive() ? 'active' : ''}`}>
            <Link to="/investments" className="menu-link">
              <i className="menu-icon tf-icons bx bx-layout"></i>
              <div data-i18n="Investment">Investment</div>
            </Link>
          </li>

          {/* Utility Bills */}
          <li className={`menu-item ${isActive('/utilitybills') ? 'active' : ''}`}>
            <Link to="/utilitybills" className="menu-link">
              <i className="menu-icon tf-icons bx bx-collection"></i>
              <div data-i18n="Utility Bills">Utility Bills</div>
            </Link>
          </li>

          {/* Account */}
          <li className={`menu-item ${isActive('/account/profile') ? 'active' : ''}`}>
            <Link to="/account/profile" className="menu-link">
              <i className="menu-icon tf-icons bx bx-user"></i>
              <div data-i18n="Account">Account</div>
            </Link>
          </li>

          {/* Logout */}
          <li className="menu-item">
            <Link className="menu-link" data-bs-toggle="modal" data-bs-target=".logout_modal">
              <i className="menu-icon tf-icons bx bx-log-out"></i>
              <div data-i18n="Logout">Logout</div>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Header;
