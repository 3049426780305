import axios from 'axios';
import Cookies from 'js-cookie';


const uRL = process.env.REACT_APP_URL;

export const deposit = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/deposit/fundaccount`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.data.status === 'success') {
            return response.data;
        } else {
            throw new Error(response.data.message || 'Failed to complete the deposit.');
        }
    } catch (error) {
        const customError = new Error('An error occurred while processing your deposit. Please try again.');
        customError.response = error.response;
        
        throw customError;
    }
};

export const fetchAllBanks = async () => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.get(`${uRL}fetchbanks`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.data.message || 'Failed to fetch banks.');
        }
    } catch (error) {
        const customError = new Error('An error occurred while fetching banks. Please try again.');
        customError.response = error.response;
        
        throw customError;
    }
};

export const fetchAccDetails = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.get(`${uRL}fetchaccountdetails?accountnumber=${data.accno}&bank=${data.code}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.data.message || 'Failed to fetch banks.');
        }
    } catch (error) {
        const customError = new Error('An error occurred while fetching banks. Please try again.');
        customError.response = error.response;
        
        throw customError;
    }
};

export const withdrawFunds = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/withdrawal/makewithdrawal`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.data.status === 'success') {
            return response.data;
        } else {
            throw new Error(response.data.message || 'Failed to complete the deposit.');
        }
    } catch (error) {
        const customError = new Error('An error occurred while processing your deposit. Please try again.');
        customError.response = error.response;
        
        throw customError;
    }
};
