import React from 'react';
import { useNavigate } from 'react-router-dom';
import BikeImg from '../../otherassets/plan-icon/bike.png';
import jetmoverImg from '../../otherassets/plan-icon/jetmover.png';
import miniBusImg from '../../otherassets/plan-icon/minibus.png';
import truckImg from '../../otherassets/plan-icon/truck.png';
import { AbbreviateNumber, formatNumberWithCommas } from '../../utilities/reusablefunctions_variables';


const InvestmentCard2 = ({ investment }) => {
    const navigate = useNavigate();

    const investmentTypeImgMap = {
        "1": BikeImg,
        "2": miniBusImg,
        "3": jetmoverImg,
        "4": truckImg,
    };
    // Determine investment type based on the plan's investment type value
    const investmentImgType = investmentTypeImgMap[investment.plan.category] || "Unknown";

    let badgeClass = '';
    let badgeText = '';
    if (investment.status === '0') {
        badgeClass = 'bg-warning';
        badgeText = 'Pending';
    } else if (investment.status === '1') {
        badgeClass = 'bg-success-dim1 text-success-dim1';
        badgeText = 'Ongoing';
    } else if (investment.status === '2') {
        badgeClass = 'bg-danger';
        badgeText = 'Expired';
    }

    return (
        <div
            className="card shadow-sm mb-3 cursor-pointer"
            key={investment.id}
            style={{
                width: '100%',
                margin: '0 auto',
                padding: '10px',
            }}
        >
            <div className="d-flex align-items-center">
                <div
                    className="rounded-circle bg-primary-dim2 d-flex align-items-center justify-content-center"
                    style={{
                        overflow: 'hidden',
                        width: '70px',
                        height: '70px',
                        padding: '5px',
                        flexShrink: 0,
                    }}
                >
                    <img
                        src={investmentImgType}
                        alt="Plan"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            borderRadius: '50%',
                        }}
                    />
                </div>

                <div className="p-2" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <h5 className="card-title fs-6 fw-bold mb-0">{investment.plan.name}</h5>
                        </div>
                        <span
                            className={`badge ${badgeClass} text-capitalize fs-c8 align-content-center`}
                        >
                            {badgeText}
                        </span>
                    </div>

                    <div className="d-flex">
                        {investment.plan.status === '2' ? ( // For Expired investment
                            <p className="card-text fs-c7 mb-0 pe-1">
                                Invested ₦{AbbreviateNumber(investment.plan.amount)} and got {investment.plan.percentage}% return in {investment.plan.no_of} {investment.plan.duration === '1' ? 'Weeks' : 'Months'}
                            </p>
                        ) : ( // For Pending and Ongoing investments
                            <p className="card-text fs-c7 mb-0 pe-1">
                                Invested ₦{AbbreviateNumber(investment.plan.amount)} to get {investment.plan.percentage}% return in {investment.plan.no_of} {investment.plan.duration === '1' ? 'Weeks' : 'Months'}
                            </p>
                        )}
                    </div>


                    <div className="d-flex justify-content-start align-items-center">
                        <div className="fs-c8 pe-5 d-flex">
                            <small className="fw-800 d-block">₦{AbbreviateNumber(investment.plan.amount)}</small>
                            <small>/unit</small>
                        </div>

                    </div>
                    <div className="fs-c8 pe-5 d-flex">
                        <small>Start date:</small>

                        <small className="fw-800 d-block ps-1"> {investment.startdate}</small>

                        <small className='ps-2'>Stop date:</small>

                        <small className="fw-800 d-block ps-1"> {investment.stopdate}</small>

                        <small className='ps-2'>Last paid date:</small>

                        <small className="fw-800 d-block ps-1"> {investment.lastpaymentdate}</small>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default InvestmentCard2;
