import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { markRead } from '../../Service/Notification/NotificationService';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import { fetchNotification } from '../../Service/Notification/NotificationService';

const NotificationCard = ({ notifications }) => {
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');
    const [notificationList, setNotificationList] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState(null);

    useEffect(() => {
        setNotificationList(notifications);
    }, [notifications]);

    // Function to fetch notifications again after marking all as read
    const fetchNotifications = async () => {
        try {
            const data = await fetchNotification(); // Fetch new notifications
            setNotificationList(data.notifications); // Update notification list
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    // Function to mark all notifications as read
    const markAllAsRead = async () => {
        try {
            const response = await fetch(`${uRL}customer/notification/markallread`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                await fetchNotifications(); // Refetch notifications after marking all as read
            } else {
                console.error('Failed to mark all notifications as read');
            }
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    const handleArchiveClick = async (notificationId) => {
        try {
            const data = { notificationid: notificationId.toString() };
            const response = await markRead(data);

            if (response.status === 'success') {
                setNotificationList((prevNotifications) =>
                    prevNotifications.filter((notification) => notification.id !== notificationId)
                );
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const markNotificationAsRead = async (notificationId) => {
        try {
            const data = { notificationid: notificationId.toString() };
            const response = await markRead(data);
            if (response.status === 'success') {
                setNotificationList((prevNotifications) =>
                    prevNotifications.map((notification) =>
                        notification.id === notificationId
                            ? { ...notification, status: '1' } // Assuming '1' means read
                            : notification
                    )
                );
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const determineImage = (notification) => {
        if (notification.which === '1' && notification.type === '1') return 'fa-plus-circle';
        if (notification.which === '1' && notification.type === '2') return 'fa-minus-square';
        if (notification.which === '2' && notification.type === '1') return 'fa-credit-card';
        if (notification.which === '2' && notification.type === '3') return 'fa-ethernet';
        if (notification.which === '2' && notification.type === '4') return 'fa-bolt';
        if (notification.which === '3' && notification.type === '1') return 'fa-truck-loading';
        if (notification.which === '3' && notification.type === '2') return 'fa-credit-card';
        return 'fa-bell';
    };

    const determineColor = (notification) => {
        if (notification.which === '1' && notification.type === '1') return 'text-success';
        if (notification.which === '1' && notification.type === '2') return 'text-danger';
        if (notification.which === '2' && notification.type === '1') return 'text-danger';
        if (notification.which === '2' && notification.type === '3') return 'text-danger';
        if (notification.which === '2' && notification.type === '4') return 'text-danger';
        if (notification.which === '3' && notification.type === '1') return 'text-primary';
        if (notification.which === '3' && notification.type === '2') return 'text-success';
        return 'text-primary';
    };

    const handleNotificationClick = (notification) => {
        setSelectedNotification(notification);
        if (notification.status === '0') {
            markNotificationAsRead(notification.id); // Mark as read only if it hasn't been read
        }
    };

    const handleCloseModal = () => {
        setSelectedNotification(null);
    };

    return (
        <div>
            {/* Button to mark all notifications as read */}
            {/* <button onClick={markAllAsRead} className="btn">
                Mark All as Read
            </button> */}

            <ul className="scrollable-container">
                <li className="list-group list-group-flush">
                    {notificationList.map((notification) => (
                        <li
                            key={notification.id}
                            className={`list-group-item list-group-item-action ${notification.status === '0' ? '' : 'bg-light'}`}
                            style={notification.status === '0' ? { backgroundColor: '#FFF2DB' } : {}}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <div className="d-flex cursor-pointer">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-container mb-3">
                                            <span className={`icon ${determineColor(notification)}`}>
                                                <i className={`fa fs-1 ${determineImage(notification)}`}></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <h6 className="mb-1">{notification.title}</h6>
                                    <p className="mb-0">{notification.description}</p>
                                    <small className="text-muted">
                                        {new Date(notification.created_at).toLocaleString()}
                                    </small>
                                </div>

                                <div className="flex-shrink-0 dropdown-notifications-actions position-relative">
                                    <Link
                                        className="dropdown-notifications-archive"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Prevent parent onClick
                                            handleArchiveClick(notification.id);
                                        }}
                                    >
                                        <span className="bx bx-x"></span>
                                    </Link>
                                    {/* Unread indicator dot */}
                                    {notification.status === '0' && (
                                        <span className="position-absolute top-50 end-0 translate-middle badge rounded-circle bg-danger" style={{ width: '8px', height: '8px' }}></span>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </li>
            </ul>

            {selectedNotification && (
  <Modal show={true} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
      <Modal.Title className="d-flex align-items-center">
        {/* Icon beside the title */}
        <span className={`icon ${determineColor(selectedNotification)} me-2`}>
          <i className={`fa ${determineImage(selectedNotification)}`}></i>
        </span>
        {selectedNotification.title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="d-flex align-items-center">
        {/* <span className={`icon ${determineColor(selectedNotification)} me-2`}>
          <i className={`fa ${determineImage(selectedNotification)}`}></i>
        </span> */}
        <p>{selectedNotification.description}</p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModal}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
)}

        </div>
    );
};

NotificationCard.propTypes = {
    notifications: PropTypes.array.isRequired,
};

export default NotificationCard;
