import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader, encryptData, formatDate, formatNumberWithCommas, formatOnlyDate, AbbreviateNumber } from '../../utilities/reusablefunctions_variables';
import WalletCard from '../../components/WalletCard/WalletCard';
import PlanCarousel from '../../components/PlanCarousel/PlanCarousel';
import TransactionList from '../../components/Transaction/TransactionList';
import Plankard from '../../components/Plankard/Plankard';

const Dashboard = () => {

  const uRL = process.env.REACT_APP_URL;
  const token = Cookies.get('userAuth');

  const navigate = useNavigate();

  // const [userID, setUserID] = useState('123');
  const [walletBalance, setWalletBalance] = useState('0.00');
  const [payoutBalance, setPayoutBalance] = useState('');
  const [noOfInvestment, setnoOfInvestment] = useState('');
  const [investedAmount, setInvestedAmount] = useState('');
  const [returningAmount, setReturningAmount] = useState('');
  const [payedoutAmount, setPaidoutAmount] = useState('');
  const [debtAmount, setDebtAmount] = useState('');
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleDashboardManagement();
  }, []);

  const handleDashboardManagement = () => {

    /* --------------------------- GET USER DETAILS --------------------------- */
    $.ajax({
      type: 'GET',
      url: uRL + 'customer',
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function (res) {
        let response = res.customer;
        // setUserID(response.codenumber);
        setWalletBalance(response.ngnbalance);
        setPayoutBalance(response.returns_ngnbalance);
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function () {

      }
    });
    /* --------------------------- END --------------------------- */
    /* --------------------------- GET Dashboard Details --------------------------- */
    $.ajax({
      type: 'GET',
      url: uRL + 'customer/dashboard/getallinvestmentdetails',
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function (response) {
        setInvestedAmount(response.investedamount);
        setReturningAmount(response.returningamount);
        setPaidoutAmount(response.payedoutamount);
        setDebtAmount(response.debtamount);
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function () {

      }
    });
    $.ajax({
      type: 'GET',
      url: uRL + 'customer/dashboard/allinvestments',
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function (response) {
        setnoOfInvestment(response.total);
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function () {

      }
    });
    /* --------------------------- END --------------------------- */
    /* ------------------------ FETCH Plans ------------------------ */
    $.ajax({
      type: 'GET',
      url: uRL + 'customer/fetchplans',
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function (response) {
        setLoading(false);
        setPlans(response.data);
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function () {

      }
    });
    /* ------------------------ END ------------------------ */
    /* ------------------------ FETCH RECENT Transactions ------------------------ */
    $.ajax({
      type: 'GET',
      url: uRL + 'customer/funding/fetchrecent',
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      },
      success: function (response) {
        // console.log('Recent Investments:', response);
        setLoading(false);
        setRecentTransactions(response);
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error('Error:', textStatus);
        console.error('Status:', xhr.status);
        // Handle the error as needed
      },
      complete: function () {

      }
    });
    /* ------------------------ END ------------------------ */
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-5">
          {/* Inside Here */}

          {/* <!-- Customer Ratings --> */}
          <div className="col-12 mb-4">
            <WalletCard balance={formatNumberWithCommas(walletBalance)} />
            <div className="row p-2">
              <Plankard icon="fa-bullseye" iconColor="#1c1071" title="No of Investment" value={noOfInvestment} />
              <Plankard icon="fa-th-list" iconColor="#ff5500" title="Invested Amount" value={`₦` + AbbreviateNumber(investedAmount)} />
              <Plankard icon="fa-table" iconColor="green" title="Returning Amount" value={`₦` + AbbreviateNumber(returningAmount)} />
            </div>
            <PlanCarousel slides={plans} />
            <TransactionList list={recentTransactions} />
          </div>
          {/* End here */}
        </div>
      </div>
    </div>
  )
}

export default Dashboard