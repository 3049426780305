import axios from 'axios';
import Cookies from 'js-cookie';

const uRL = process.env.REACT_APP_URL;

//fetch data plans
export const fetchElectrictyPlans = async (code) => {
    const token = Cookies.get('userAuth');

    try {
        // Make the API call to fetch data plans
        const response = await axios.get(`${uRL}customer/vtu/fetchelectrictyplans/${code}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Return the response data if the call is successful
        return response.data;
    } catch (error) {
        // Log and handle the error appropriately
        console.error('Error fetching electric plans:', error);
        throw error;
    }
};


//purchase electricty subscription
export const electricSubscription = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/vtu/electricty`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; 
        } else {
            throw new Error('Failed to process electricty purchase');
        }
    } catch (error) {
        console.error('Error purchasing electricty subscription:', error);
        throw error; // re-throw the error for further handling
    }


};


//fetch electricty codes
export const fetchElectricCodes = async () => {
    const token = Cookies.get('userAuth');

    try {
        // Make the API call to fetch data plans
        const response = await axios.get(`${uRL}customer/vtu/fetchelectrictycodes`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Return the response data if the call is successful
        return response.data;
    } catch (error) {
        // Log and handle the error appropriately
        console.error('Error fetching electric codes:', error);
        throw error;
    }


};



//verfiy meter number
export const verfiyMeterNumber = async (verfiydata) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/vtu/verfiymeterno`, verfiydata, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; 
        } else {
            throw new Error('Failed to Verfiy number');
        }
    } catch (error) {
        console.error('Error verifying meter number:', error);
        throw error; // re-throw the error for further handling
    }


};