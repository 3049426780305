import React from 'react';
import { useLocation } from 'react-router-dom';
import { formatNumberWithCommas, formatDate } from '../../utilities/reusablefunctions_variables';

const TransactionDetail = () => {
    const location = useLocation();
    const transaction = location.state;

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className="card transaction-card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span className={`badge rounded-pill ${transaction?.badgeColor}`}>
                                {transaction?.listTitle || "Transaction"}
                            </span>
                            <button
                                className="btn-close"
                                aria-label="Close"
                                onClick={() => window.history.back()}
                            ></button>
                        </div>
                        <div className="card-body text-center">
                            <div className="avatar-container mb-3">
                                <span className={`icon ${transaction?.iconColor}`}>
                                    <i className={`fa fs-1 ${transaction?.iconName}`}></i>
                                </span>
                            </div>
                            <p className="transaction-date mb-1">{formatDate(transaction?.date)}</p>
                            <p className="transaction-description fw-bold">{transaction?.description}</p>
                            <h4 className="transaction-amount">{`₦${formatNumberWithCommas(transaction?.amount)}`}</h4>
                            <div className='d-flex justify-content-start'>
                            <span
                                className={`badge rounded-pill  ${transaction?.tstatus === '1' ? 'bg-success' : 'bg-danger'
                                    }`}
                            >
                                {transaction?.tstatus === '1' ? 'Completed' : 'Pending'}
                            </span>  
                            </div>

                            {/* Display additional withdrawal details if it's a "Withdrawal" */}
                            {transaction?.listTitle === 'Withdrawal' && transaction?.details && (
                                <div className='border p-2 m-2 rounded'>

                                    <div className="withdrawal-details mt-4 text-start">
                                        {/* <h5 className="mb-3">Withdrawal Details</h5> */}
                                        <div className="mb-2">
                                            <strong>Account Name:</strong> {transaction.details?.accountname}
                                        </div>
                                        <div className="mb-2">
                                            <strong>Account Number:</strong> {transaction.details?.accountnumber}
                                        </div>
                                        <div className="mb-2">
                                            <strong>Bank:</strong> {transaction.details?.bankname}
                                        </div>
                                        <div className="mt-3">
                                            <strong>Status:</strong>{" "}
                                            <span
                                                className={`badge rounded-pill  ${transaction.details?.status === '1' ? 'bg-success' : 'bg-danger'
                                                    }`}
                                            >
                                                {transaction.details?.tstatus === '1' ? 'Completed' : 'Failed'}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetail;
