/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import comingSoonImg from '../../src/assets/image/icons/coming.png';

function ComingSoonScreen() {
  return (
    <div
      className="d-flex vh-100 justify-content-center align-items-start text-dark position-relative"
      style={{
        backgroundColor: '#ffffff', // White background
        overflow: 'hidden',
      }}
    >
      {/* Main Content */}
      <div className="container text-center position-relative" style={{ zIndex: 1 }}>
        {/* Coming Soon Image */}
        <img
          src={comingSoonImg}
          alt="Coming Soon"
          className="img-fluid"
          style={{ width: '400px' }}
        />

        {/* Message */}
        <h2 className="mb-4"></h2>

        {/* Back to Home Button */}
        <button
          className="btn btn-primary"
          onClick={() => window.history.back()}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
}

export default ComingSoonScreen;
