import React from 'react'
import flip from '../../assets/image/icons/flip-backward.png'
import airtime from '../../assets/image/icons/phone.png'
import data from '../../assets/image/icons/data.png'
import electricity from '../../assets/image/icons/electricity.png'
import cable from '../../assets/image/icons/cable.png'
import cart from '../../assets/image/icons/cart.png'
import UtilityCard from '../../components/Utility/UtilityCard'

const UtilityBills = () => {
    const isLargeScreen = window.innerWidth >= 992; // large breakpoint

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">

                    {/* ///////// content//////////// */}
                    <div className="card shadow-sm mb-4" style={{ height: isLargeScreen ? '120%' : '100%' }}>
                        <div className="card-body">
                            <div className='container'>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="card-title" onClick={() => window.history.back()}><img src={flip} alt='flip-backward' /></h5>
                                </div>
                                <h4 className="card-text mb-4">
                                    Utility
                                </h4>
                                <h6 className='mb-4'>
                                    Bills
                                </h6>
                            </div>

                            {/* Utility cards */}
                            <div className="container">
                                <div className="row">
                                    <UtilityCard
                                        title="Airtime Top-Up"
                                        image={airtime}
                                        link="airtime"
                                        isAirtime={true} // Apply different styling
                                    />
                                    <UtilityCard
                                        title="Data Subscription"
                                        image={data}
                                        link="data-subscription"
                                        isAirtime={true}
                                    />
                                    <UtilityCard
                                        title="Electricity Bill"
                                        image={electricity}
                                        link="electricty"
                                        isAirtime={true}
                                    />
                                    <UtilityCard
                                        title="Cable Subscription"
                                        image={cable}
                                        link="cable"
                                        isAirtime={true}
                                    />
                                    {/* <UtilityCard
                                        title="Online Shopping"
                                        image={cart}
                                        link="#"
                                    /> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default UtilityBills