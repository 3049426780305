import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

const ChangePin = ({ closeChangePinModal, handleSubmitChangePin, isSubmitting }) => {
    const [oldpin, setOld] = useState(['', '', '', '']);
    const [newpin, setNew] = useState(['', '', '', '']);
    const [step, setStep] = useState(1); // Step 1: Enter old PIN, Step 2: Enter new PIN

    const inputRefs = useRef([]);

    const handleChange = (index, value, isOldPin) => {
        if (value.match(/^[0-9]$/) || value === '') {
            const updatedPin = isOldPin ? [...oldpin] : [...newpin];
            updatedPin[index] = value;

            isOldPin ? setOld(updatedPin) : setNew(updatedPin);

            if (value.match(/^[0-9]$/) && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }

            if (value === '' && index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handleNextStep = () => {
        if (oldpin.every((digit) => digit !== '')) {
            setStep(2);
        } else {
            toast.error('Please enter a valid 4-digit old PIN.', {
                autoClose: 3000,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newpin.every((digit) => digit !== '')) {
            handleSubmitChangePin(oldpin, newpin);
        } else {
            toast.error('Please enter a valid 4-digit new PIN.', {
                autoClose: 3000,
            });
        }
    };

    return (
        <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{step === 1 ? 'Enter Old PIN' : 'Enter New PIN'}</h5>
                        <button type="button" className="btn-close" onClick={closeChangePinModal}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body d-flex justify-content-center gap-2">
                            {(step === 1 ? oldpin : newpin).map((digit, index) => (
                                <input
                                    key={index}
                                    type="password"
                                    maxLength={1}
                                    className="form-control"
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value, step === 1)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        width: '50px',
                                        margin: '0 5px',
                                    }}
                                />
                            ))}
                        </div>
                        <div className="modal-footer">
                            {step === 1 ? (
                                <button type="button" className="btn btn-primary" onClick={handleNextStep} disabled={isSubmitting}>
                                    Next
                                </button>
                            ) : (
                                <>
                                    <button type="button" className="btn btn-secondary" onClick={closeChangePinModal} disabled={isSubmitting}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePin;
