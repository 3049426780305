import React from 'react'
import './PlanCarousel.css'
import PlanCard from './PlanCard';

const PlanCarousel = ({slides}) => {
    return (
        <>
            <h6 className='h-6'>Special Offers</h6>
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="true">
                <div className="carousel-inner">
                    {/* <div className="carousel-item active">
                    <PlanCard slide={slides}/>
                    </div> */}

                    {slides.map((slide, index) => (
                    <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                        <PlanCard slide={slide}/>
                    </div>
                    ))}

                </div>

                {/* Carousel Controls */}
                <button className="carousel-control-prev ms-1" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next me-1" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    );
};

export default PlanCarousel;
