import axios from 'axios';
import Cookies from 'js-cookie';

const uRL = process.env.REACT_APP_URL;

export const fetchNotification = async (page = 1, read = '', type = '', which = '') => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.get(`${uRL}customer/notification/fetchnotifications`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            params: { page, read, type, which }
        });

        if (response.status === 200) {
            return response.data; // success response
        } else {
            throw new Error('Failed to get notification');
        }
    } catch (error) {
        console.error('Error fetching notifications', error);
        throw error;
    }
};


export const markRead = async (data) => {
    const token = Cookies.get('userAuth');

    try {
        const response = await axios.post(`${uRL}customer/notification/markread`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Handle the response accordingly
        if (response.data.status === 'success') {
            return response.data; // success response
        } else {
            throw new Error('Failed to process airtime purchase');
        }
    } catch (error) {
        console.error('Error purchasing airtime:', error);
        throw error; // re-throw the error for further handling
    }

}
export const markAllAsRead = async () => {
    const token = Cookies.get('userAuth');
    try {
        const response = await axios.post(`${uRL}customer/notification/markallread`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data; // Success response
        } else {
            throw new Error('Failed to mark all notifications as read');
        }
    } catch (error) {
        console.error('Error marking all notifications as read:', error);
        throw error; // re-throw the error for further handling
    }
};
