import React from 'react'

// Helper component for input fields
const FormInput = ({ label, id, type, options, value, onChange, placeholder, min, max, readOnly }) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">{label}</label>
      {type === 'select' ? (
        <select
          className="form-control"
          id={id}
          style={{ backgroundColor: '#F8F8F8', border: '1px solid #92A4B5', height: '50px', borderRadius: '16px' }}
          value={value}
          onChange={onChange}
        >
          <option value="" disabled>{placeholder}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}

        </select>
      ) : (
        <input
          type={type}
          className="form-control"
          id={id}
          style={{ backgroundColor: '#F8F8F8', border: '1px solid #92A4B5', height: '50px', borderRadius: '16px' }}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          {...(type === 'number' && { min, max })}
        />
      )}
    </div>
  );
}

export default FormInput


