import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import flip from '../../assets/image/icons/flip-backward.png';
import FormInput from '../../components/Form/FormInput';
import { fetchDataPlans } from '../../Service/Utility/DataService';

const DataSubscription = () => {
  const isLargeScreen = window.innerWidth >= 992; // large breakpoint
  const [network, setNetwork] = useState('');
  const [productCode, setProductCode] = useState('');
  const [phone, setPhone] = useState('');
  const [amount, setProductAmount] = useState('');
  const [productName, setProductName] = useState('');
  const [dataPlans, setDataPlans] = useState([]);

  const navigate = useNavigate(); // For page navigation

  const networkMap = {
    "01": "MTN",
    "02": "Glo",
    "03": "m_9mobile",
    "04": "Airtel",
  };

  useEffect(() => {
    if (network) {
      const networkName = networkMap[network];
      fetchDataPlans(networkName)
        .then(response => {
          if (response.success) {
            setDataPlans(response.data[0].PRODUCT);
          } else {
            toast.error('Failed to fetch data plans');
          }
        })
        .catch(err => {
          console.error(err);
          toast.error('An error occurred while fetching data plans');
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const validateInputs = () => {
    if (!network || !phone || !amount || !productCode) {
      toast.error('Please fill all fields.');
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      navigate('/review/data-subscription', { //navigate to review page, with state containing data
        state: {
          number: phone,
          amount,
          biller: network,
          productName,
          productCode,
        },
      });
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-5">
          <div className="card shadow-sm mb-4" style={{ height: isLargeScreen ? '120%' : '100%' }}>
            <div className="card-body">
              <div className='container'>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title" onClick={() => window.history.back()}>
                    <img src={flip} alt="flip-backward" />
                  </h5>
                </div>
                <h4 className="card-text mb-4">Buy Data</h4>
              </div>

              {/* Form Section */}
              <form onSubmit={handleSubmit}>
                <div className="container">
                  <FormInput
                    label="Network"
                    id="network"
                    type="select"
                    options={[
                      { value: '01', label: 'MTN' },
                      { value: '02', label: 'GLO' },
                      { value: '03', label: '9MOBILE' },
                      { value: '04', label: 'AIRTEL' }
                    ]}
                    value={network}
                    onChange={handleInputChange(setNetwork)}
                    placeholder="Select Network"

                  />

                  <FormInput
                    label="Data Plan"
                    id="productCode"
                    type="select"
                    options={dataPlans.map(plan => ({
                      value: plan.PRODUCT_ID,
                      label: plan.PRODUCT_NAME,
                    }))}
                    value={productCode}
                    onChange={(e) => {
                      const selectedPlan = dataPlans.find(plan => plan.PRODUCT_ID === e.target.value);
                      setProductCode(e.target.value);
                      setProductName(selectedPlan?.PRODUCT_NAME || '');
                      setProductAmount(selectedPlan?.PRODUCT_AMOUNT || '');
                    }}
                    placeholder="Select Data Plan"

                  />

                  <FormInput
                    label="Phone Number"
                    id="phone"
                    type="text"
                    value={phone}
                    onChange={handleInputChange(setPhone)}
                    placeholder="Enter phone number"
                  />

                  <button
                    className="btn w-100 nlbtn p-3"
                    style={{ backgroundColor: '#FB7B00', color: '#ffffff', borderRadius: '16px' }}
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSubscription;
