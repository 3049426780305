import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Verified from '../../assets/image/icons/check-verified.png';
import edit from '../../assets/image/icons/edit.png';
import placeholderImg from '../../assets/image/profile/placeholder.png';
import ProfileStats from '../../components/Profile/ProfileStats';
import userImg from '../../assets/image/profile/user.png';
import shieldImg from '../../assets/image/profile/shield.png';
import logoutImg from '../../assets/image/profile/logout.png';
import deleteImg from '../../assets/image/icons/delete.png';
import sadImg from '../../assets/image/icons/sademoji.png';

import Cookies from 'js-cookie';

import informationImg from '../../assets/image/profile/information.png';
import customerSupportImg from '../../assets/image/profile/customerSupport.png';
import chevronRightImg from '../../assets/image/profile/chevronRight.png';
import { getUser } from '../../Service/GetUser/UserService';
import { Spinner } from 'react-bootstrap';
import DpModal from '../../components/Profile/DpModal';
import { changeDp } from '../../Service/Profile/ProfileService';
import { toast } from 'react-toastify';



const Profile = () => {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');

    function clearCookies() {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        }
    }

    const logout = async () => {
        const logoutURL = uRL + "customer/logout";

        try {
            toast.warning("Logging you out...");

            const response = await fetch(logoutURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();

                localStorage.clear();
                clearCookies();
                setTimeout(() => {
                    window.location.replace("/login");
                }, 2000);
            } else {
                const errorData = await response.json();
                if (errorData && errorData.message) {
                    toast.error(
                        Array.isArray(errorData.message) ? errorData.message[0] : errorData.message
                    );
                } else {
                    toast.error("Failed to log out. Please try again.");
                }
                localStorage.clear();
                clearCookies();
                setTimeout(() => {
                    window.location.replace("/login");
                }, 2000);
            }
        } catch (error) {
            console.error("Error during logout:", error);
            toast.error("An unexpected error occurred. Redirecting to login.");
            localStorage.clear();
            clearCookies();
            setTimeout(() => {
                window.location.replace("/login");
            }, 2000);
        }
    };

    const fetchUserData = async () => {
        try {
            const data = await getUser();
            setUserData(data.customer);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
    const deleteAccount = async () => {
        const deleteAccountURL = uRL + 'customer/deleteaccount';
        try {
            toast.warning('Deleting your account...');
            const response = await fetch(deleteAccountURL, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`, // Replace with your token logic
                },
            });

            if (response.ok) {
                toast.success('Account deleted successfully.');
                localStorage.clear();
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                const errorData = await response.json();
                toast.error(errorData.message || 'Failed to delete account. Please try again.');
            }
        } catch (error) {
            console.error('Error during account deletion:', error);
            toast.error('An unexpected error occurred.');
        }
    };

    // Call fetchUserData initially
    useEffect(() => {
        fetchUserData();
    }, []);

    if (!userData) {
        return <>
            <div className='text-primary' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <Spinner />
            </div>
        </>;
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    {/* Profile Section */}
                    <div className="row justify-content-center text-center">
                        <div className="col-6 col-md-4" style={{ maxWidth: '250px' }}>
                            <div style={{ position: 'relative' }}>
                                <img
                                    src={userData.profilepicture || placeholderImg}
                                    alt="Profile"
                                    className="rounded-circle img-fluid mb-2"
                                    style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                                />
                            </div>
                            <h4 style={{ marginTop: '10px', fontWeight: 'bold' }}>
                                {`${userData.firstname} ${userData.lastname}`}
                            </h4>
                            <p className="text-muted" style={{ marginBottom: '8px' }}>{userData.email}</p>
                            <div
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    backgroundColor: '#0077F7',
                                    borderRadius: '20px',
                                    padding: '5px 10px',
                                    marginTop: '5px',
                                }}
                            >
                                <img
                                    src={Verified}
                                    alt="Verified"
                                    style={{ width: '16px', height: '16px', marginRight: '5px' }}
                                />
                                <span className="text-white" style={{ fontWeight: '100', fontSize: '0.8rem' }}>
                                    {userData.status === '1' ? 'Verified' : 'Not Verified'}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Stats Section */}
                    <ProfileStats />

                    {/* Menu Section */}
                    <ul className="list-group mt-4">
                        <MenuItem icon={userImg} label="Edit Profile" link="/account/profile/edit" />
                        <MenuItem icon={shieldImg} label="Security" link="/account/security" />
                        <MenuItem icon={customerSupportImg} label="Contact Support" link="/account/customer-support" />
                        <MenuItem
                            data-bs-toggle="modal"
                            data-bs-target="#logout_modal"

                            icon={logoutImg} label="Log Out" textColor="text-danger" link="#" />
                        <MenuItem
                            icon={deleteImg}
                            label="Delete Account"
                            textColor="text-danger"
                            link="#"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_modal"
                        />
                    </ul>
                </div>
            </div>

            {/* Delete Account Modal */}
            <div className="modal custom-modal fade mymodal" id="delete_modal" role="dialog" style={{ zIndex: '10000' }}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header text-center">
                                <h3>Delete Account</h3>
                                <img src={sadImg} alt='delete' style={{ marginRight: '10px' }} />

                                <p className='fs-10'>This action is irreversible, do you want to continue to delete account?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="d-flex justify-content-between">
                                        <span data-bs-dismiss="modal" className="btn btn-danger" onClick={deleteAccount}>
                                            Delete
                                        </span>
                                        <span data-bs-dismiss="modal" className="btn btn-secondary">
                                            Cancel
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Stare Logout Modal */}
            <div className="modal custom-modal fade mymodal logout_modal" id="logout_modal" role="dialog" style={{ zIndex: '10000' }}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header text-center">
                                <h3>Logout</h3>

                                <p>Are you sure you want to logout?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="d-flex justify-content-between">
                                        <span data-bs-dismiss="modal" className="btn btn-primary logout-continue-btn" onClick={logout}>Logout</span>
                                        <span data-bs-dismiss="modal" className="btn btn-secondary logout-cancel-btn">Cancel</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const MenuItem = ({ icon, label, textColor = 'text-dark', link, ...rest }) => {
    return (
        <>
            <a href={link}>
                <div
                    className="d-flex justify-content-between align-items-center p-3 mt-2"
                    style={{ backgroundColor: '#F8F8F8' }}
                    {...rest}
                >
                    <div className="d-flex align-items-center">
                        <img src={icon} alt={label} style={{ marginRight: '10px' }} />
                        <span className={`${textColor} fw-bold`}>{label}</span>
                    </div>
                    <a href={link}>
                        <img src={chevronRightImg} alt="Chevron Right" />
                    </a>
                </div>
            </a>
        </>
    );
};
export default Profile;
