import React from 'react';
import flip from '../../assets/image/icons/flip-backward.png';
import arrowImg from '../../assets/image/profile/arrow-square.png';

const AboutPage = () => {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-5">
                    <div className='container'>
                        {/* Back Button */}
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title" onClick={() => window.history.back()}>
                                <img src={flip} alt="flip-backward" style={{ cursor: 'pointer' }} />
                            </h5>
                        </div>

                        {/* Page Title */}
                        <h4 className="text-dark mb-4">About</h4>

                        {/* Menu List */}
                        <ul className="list-group">
                            {/* Privacy Policy Item */}
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                                <span className="fw-bold">Privacy Policy</span>
                                <img src={arrowImg} alt="arrow"  />
                            </li>
                            <hr className="my-0" />
                            
                            {/* Terms of Service Item */}
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                                <span className="fw-bold">Terms of Service</span>
                                <img src={arrowImg} alt="arrow"  />
                            </li>
                            <hr className="my-0" />
                            
                            {/* Security and Compliance Item */}
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
                                <span className="fw-bold">Security and Compliance</span>
                                <img src={arrowImg} alt="arrow"  />
                            </li>
                            <hr className="my-0" />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
