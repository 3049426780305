import { TbArrowBackUp } from "react-icons/tb";
// import { FaApple } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormRow from "./components/FormRow";
import SubmitBtn from "./components/SubmitBtn";
import customFetch from "../../utilities/axios";
import { AuthContext } from "./context_api/AuthContext";

const Register = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { setAuthStage } = useContext(AuthContext);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  useEffect(() => {
    setAuthStage(1); // Start the stage at 1
  }, []);

  // useEffect(() => {
  //   console.log("Register component mounted");

  //   return () => {
  //     console.log("Register component unmounted");
  //   };
  // }, []);


  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error("All fields are required");
      return;
    }
    setIsLoading(true);
    try {
      const response = await customFetch.post("customer/auth/getstarted", {
        email,
        password,
      });

      if (response.status === 201) {
        console.log('cccccccccccccc');

        console.log(response.data);
        toast.success(response?.data?.message || "OTP sent to your email!");
        navigate("/register/otp", { state: { email, password } });
        // console.log(navigate);
        setAuthStage(2);
      } else {
        throw new Error(response.data.message || "Failed to send OTP");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Error in sending OTP");
      if (error?.response?.status === 422) {
        toast.error(error?.message || "Bad Request");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* Register */}
          <div className="card">
            <div className="card-body">
              {/* Navigate back */}
              <button
                className="navigate-btn mb-10"
                onClick={() => navigate(-1)}
              >
                <TbArrowBackUp />
              </button>
              {/* <!--  --> */}
              <div className="d-flex flex-column register-container mt">
                <h4 className="mb-2">Create Account</h4>
                <p className="mb-4">
                  Create an account with Gatober to start your wealth journey.
                </p>
                <form id="userLogin" onSubmit={onSubmit} className="mb-4">
                  <FormRow
                    labelText="Email"
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                    placeholder="Type in your email address"
                  />

                  <div className="mb-3 form-password-toggle">
                    <label className="form-label capitalize" htmlFor="password">
                      Create Password
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        aria-describedby="password"
                        minLength={8}
                        required
                      />
                      <span className="input-group-text cursor-pointer">
                        <i
                          className={`bx ${!passwordVisible ? "bx-hide" : "bx-show"
                            } toggle-password`}
                          onClick={togglePasswordVisibility}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <SubmitBtn
                    type="submit"
                    isLoading={isLoading}
                    text="Submitting..."
                  />
                </form>

                <p className="text-center mb-4">
                  <span>Already have account? </span>
                  <Link to="/login">
                    <span> Sign In</span>
                  </Link>
                </p>
                <p className="text-center mb-4">
                  Creating an account on Gatober means you have agreed to our
                  <Link to="/services">
                    <span> Terms of service </span>
                  </Link>
                  and
                  <Link to="/policies">
                    <span> Privacy Policy.</span>
                  </Link>
                </p>
              </div>
              {/* <p className="text-center mb-2">
                <span>or</span>
              </p>
              <div className="text-center mb-4">
                <button className="btn w-100">
                  <FaApple className="mr-2" />
                  <span>Login with Apple</span>
                </button>

                <button className="btn w-100">
                  <FcGoogle />
                  <span>Login with Google</span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
