import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

const SetPin = ({ closeSetPinModal, handleSubmitSetPin, isSubmitting }) => {
    const [newpin, setNew] = useState(['', '', '', '']);
    const [confirmpin, setConfirm] = useState(['', '', '', '']);
    const [step, setStep] = useState(1); // Step 1: New PIN, Step 2: Confirm PIN

    const inputRefs = useRef([]);

    const handleChange = (index, value, isNewPin) => {
        if (value.match(/^[0-9]$/) || value === '') {
            const updatedPin = isNewPin ? [...newpin] : [...confirmpin];
            updatedPin[index] = value;

            isNewPin ? setNew(updatedPin) : setConfirm(updatedPin);

            if (value.match(/^[0-9]$/) && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }

            if (value === '' && index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handleNextStep = () => {
        if (newpin.every((digit) => digit !== '')) {
            setStep(2); // Move to the next step
        } else {
            toast.error('Please enter a valid 4-digit PIN.', {
                autoClose: 3000,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (confirmpin.every((digit) => digit !== '')) {
            if (newpin.join('') === confirmpin.join('')) {
                handleSubmitSetPin(newpin, confirmpin);
            } else {
                toast.error('PINs do not match. Please try again.', {
                    autoClose: 3000,
                });
            }
        } else {
            toast.error('Please enter a valid 4-digit PIN.', {
                autoClose: 3000,
            });
        }
    };

    return (
        <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{step === 1 ? 'Set New PIN' : 'Confirm New PIN'}</h5>
                        <button type="button" className="btn-close" onClick={closeSetPinModal}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body d-flex justify-content-center gap-2">
                            {(step === 1 ? newpin : confirmpin).map((digit, index) => (
                                <input
                                    key={index}
                                    type="password"
                                    maxLength={1}
                                    className="form-control"
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value, step === 1)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        width: '50px',
                                        margin: '0 5px',
                                    }}
                                />
                            ))}
                        </div>
                        <div className="modal-footer">
                            {step === 1 ? (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleNextStep}
                                    disabled={isSubmitting}
                                >
                                    Next
                                </button>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={closeSetPinModal}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SetPin;
