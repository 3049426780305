import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authStage, setAuthStage] = useState(() => {
    const savedStage = localStorage.getItem("authStage");
    return savedStage !== null ? parseInt(savedStage) : null; // Return null if no value
  });

  const updateAuthStage = (newStage) => {
    if (typeof newStage === "number" && newStage >= 0) {
      setAuthStage(newStage);
    } else {
      console.warn("Invalid authStage value:", newStage);
    }
  };

  useEffect(() => {
    if (authStage !== null) {
      // Only update localStorage if authStage has a value
      localStorage.setItem("authStage", authStage);
    }
  }, [authStage]);

  return (
    <AuthContext.Provider value={{ authStage, setAuthStage: updateAuthStage }}>
      {children}
    </AuthContext.Provider>
  );
};
