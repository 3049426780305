import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FormRow = ({
  type,
  name,
  value,
  handleChange,
  labelText,
  placeholder,
}) => {
  return (
    <div className="mb-1">
      <label htmlFor={name} className="form-label capitalize">
        {labelText}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        className="form-control mb-3"
        placeholder={placeholder}
        required
        autoFocus={true}
      />
    </div>
  );
};

export const SelectInput = ({ arrays, labelText, placeholder, name }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="mb-3 position-relative">
      <label htmlFor={name} className="form-label capitalize">
        {labelText}
      </label>
      <div className="position-relative">
        <input
          type="text"
          readOnly
          className="form-control"
          placeholder={placeholder}
          value={selectedItem}
          onClick={toggleDropdown}
          required
        />
        <span
          className="position-absolute"
          style={{
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={toggleDropdown}
        >
          {dropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </div>
      {dropdownOpen && (
        <ul
          className="dropdown-menu show position-absolute w-100"
          style={{ top: "100%", zIndex: 1 }}
        >
          {arrays.map((array, i) => (
            <li key={i} onClick={() => handleItemSelect(array)}>
              <button className="dropdown-item">{name}</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormRow;
