import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import Cookies from 'js-cookie';
import TransactionCard from '../../components/Transaction/TransactionCard';
import { Spinner } from 'react-bootstrap';




const AllTransactions = () => {
    const isEmpty = (obj) => Object.entries(obj).length === 0;

    const uRL = process.env.REACT_APP_URL;
    const token = Cookies.get('userAuth');
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchTransactions = (page) => {
        setLoading(true);
        $.ajax({
            type: 'GET',
            url: `${uRL}customer/funding/fetchall?perpage=10&page=${page}`,
            dataType: 'json',
            beforeSend: (xhr) => {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            },
            success: (response) => {
                setLoading(false);
                setTransactions((prev) => [...prev, ...response.data]);
                setCurrentPage(response.current_page);
                setHasMore(response.current_page < response.last_page);
            },
            error: (xhr, textStatus, errorThrown) => {
                console.error('Error:', textStatus);
                console.error('Status:', xhr.status);
                setLoading(false);
            },
        });
    };

    // Initial fetch and load more on button click
    useEffect(() => {
        fetchTransactions(currentPage);
    }, []);

    const loadMoreTransactions = () => {
        fetchTransactions(currentPage + 1);
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-12">

                    
                    <div className="row justify-content-center overflow-auto" style={{height: '82vh'}}>
                        <div className="col-12 col-md-4" style={{ maxWidth: '1000px' }}>
                            <div className="card-body py-0 px-2">
                                {loading && currentPage === 1 ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : isEmpty(transactions) ? (
                                    <span>No transaction yet.</span>
                                ) : (
                                    <ul className="p-0 m-0">
                                        {transactions && transactions.map((transaction) => (
                                            <TransactionCard
                                                key={transaction.id}
                                                tid={transaction.id}
                                                ttype={transaction.type}
                                                twhich={transaction.which}
                                                tclasstype={transaction.classtype}
                                                description={transaction.title}
                                                amount={transaction.amount}
                                                date={transaction.date + ', ' + transaction.time}
                                                details={transaction.details}
                                                tstatus={transaction.status}
                                            />
                                        ))}

                                        {hasMore && !loading && (
                                            <div className='d-flex justify-content-center'>
                                                <button
                                                    onClick={loadMoreTransactions}
                                                    className="btn btn-primary btn-sm mt-3"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Loading...' : 'Load More'}
                                                </button>
                                            </div>
                                        )}

                                        {loading && currentPage > 1 && (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        )}
                                            </ul>
                                        )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default AllTransactions;



